@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.g-table {
  display: table;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-collapse: collapse;
  border-spacing: 0;
  margin: $g-unit 0;
  width: 100%;

  &__row {
    border-bottom: 1px solid $color-border;
    background-color: $color-white;

    &__header,
    &__cell {
      color: $color-text-grey;
      font-weight: normal;
      border-right: 1px solid $color-border;
      height: $g-unit * 4;
      line-height: 33px;
      padding: 0 9px;
      text-align: left;
      font-size: $small-font-size;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      &:last-child {
        border-right: 0px;
      }

      &--selected {
        &--up,
        &--down {
          &:after {
            color: $color-text-grey !important;
            visibility: visible;
          }
        }
      }
    }

    &__header {
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      padding-right: 26px;
    }

    &:last-child {
      border-bottom: 0px;
    }

    &--total > &__cell {
      font-weight: 500 !important;
    }
  }
}
