@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ReadOnlyInput {
  position: relative;
  .g-input,
  .g-input:focus {
    background-color: $color-white;
    color: $color-black !important;
    border-color: $color-grey-1;
    padding-right: 26px;
  }

  & .Tooltip--clicked &__search-icon-container__icon {
    fill: $color-ui-primary !important;
  }

  &__search-icon-container {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: $color-grey-4;
    &:active {
      color: $color-black;
    }
    svg {
      transition: all $transition-time ease;
    }
  }
}
