@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.info {
  display: inline-block;
  width: $g-unit * 3;
  height: $g-unit * 3;
  user-select: none;

  &__button {
    width: $g-unit * 3;
    height: $g-unit * 3;
    border-radius: 50%;
    border: 1px solid $color-grey-2;
    background-color: $color-white;
    color: $color-grey-2;
    text-align: center;
    line-height: 23px;
    font-weight: 200;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    &:active {
      color: $color-white;
      background-color: $color-grey-2;
    }
  }

  &__bubble {
    $bubbleWidth: 160px;
    width: $bubbleWidth;
    background-color: $color-text-grey;
    position: absolute;
    top: 36px;
    left: (-1 * (($bubbleWidth / 2) - 12px));
    text-align: left;
    border-radius: $g-unit;
    padding: 7px 8px;
    z-index: $z-index-info-bubble;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transform: translateY(0px);
    box-shadow: 0 0 1px rgba(black, 0.6), 0 0 $g-unit-double rgba(black, 0.3);
    transition: all 110ms ease;

    &:after {
      content: "";
      width: $g-unit * 3;
      height: $g-unit * 3;
      border-radius: 5px;
      position: absolute;
      background-color: inherit;
      left: (($bubbleWidth / 2) - 12px);
      top: -4px;
      transform: rotate(45deg);
      z-index: -1;
    }

    span {
      display: block;
      color: $color-white;
      user-select: none;
    }
  }

  &--hidden {
    .info__bubble {
      visibility: hidden;
      transform: translateY(-6px);
      pointer-events: none;
    }
  }
}
