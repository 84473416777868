@import "~stylesheets/utilities/variables.scss";

.InputPassword {
  &__input-container {
    &__input {
      padding-right: $input-height !important;
    }

    &__button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100% !important;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
