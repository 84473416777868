@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ButtonGroup {
  display: inline-flex;

  &--disabled {
    pointer-events: none;
  }

  &--stretch {
    width: 100%;
  }

  &__button {
    min-width: 85px;
    cursor: pointer;
    border-radius: 0;
    background-color: $color-white;
    outline: none;
    color: $color-text-main;
    box-shadow: none;
    border: 1px solid $color-border-button;
    z-index: 0;
    margin-right: -1px;
    flex: 1;

    &--active,
    &:active {
      background-color: $color-teal-100;
      border: 1px solid $color-action-secondary;
      z-index: 1;
    }

    &:first-child {
      border-radius: $small-border-radius 0 0 $small-border-radius;
    }

    &:last-child {
      border-radius: 0 $small-border-radius $small-border-radius 0;
    }

    &--disabled {
      color: $color-grey-4;
    }
  }
}
