@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.ModalMoveTraining {
  &__title {
    border-bottom: none !important;
  }

  &__description {
    width: 100%;
    height: 100%;
    color: $color-black;
    font-size: $main-font-size;
    padding-top: 0px !important;
    line-height: $medium-line-height;
    overflow: visible !important;
  }

  &__response-selector {
    margin-top: 24px;
    margin-bottom: $g-unit;
  }

  &__bottom {
    padding: 0 !important;
    border-radius: 0 0 $main-border-radius $main-border-radius !important;
  }

  &__button {
    width: 100%;
    height: 100%;
    border: none;
    font-size: $main-font-size;
    font-weight: $medium-font-weight;
    box-shadow: none !important;
    outline: none;
    padding: $g-unit 0;
  }

  &__error-list {
    margin: 10px 0 26px;
    border: 1px solid $color-grey-2;
    border-radius: $main-border-radius;

    &--warning-icon {
      height: $icon-height;
      vertical-align: middle;
      margin-right: $g-unit;
    }

    &--container {
      color: $color-black;
    }
  }
}
