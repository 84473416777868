@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Tooltip {
  width: 20px;
  height: 20px;
  margin: 0 $g-unit-half;
  background-color: transparent;
  color: $color-grey-3;
  transition: color 120ms ease;
  user-select: none;
  white-space: normal;
  cursor: default;

  &__blurb {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    background: $color-black;
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06),
      0 0 $g-unit-double 0 rgba(0, 0, 0, 0.08);
    width: auto;
    white-space: pre-line;

    &--right,
    &--left {
      min-height: 52px;
    }

    &--up,
    &--down {
      min-height: 25px;
    }
  }

  &__blurb-image-container {
    display: block;
    background: white;
    border-radius: $g-unit;
    text-align: center;
    overflow: hidden;
  }

  &__blurb-image {
    width: 100%;
    display: block;
  }

  &__blurb-text {
    font-weight: $main-font-weight;
    font-size: $x-small-font-size;
    text-align: center;
    line-height: $x-small-line-height;
    -webkit-font-smoothing: antialiased;
    margin: 0 $g-unit;
    color: $color-white;
  }

  &__blurb-image-container + &__blurb-text {
    margin-top: $g-unit;
  }

  &__container {
    z-index: $z-index-tooltip;
    pointer-events: none;
    position: absolute;
    min-width: 140px;
    max-width: 184px;
    transform: translateY(
      -9999px
    ); // To prevent the tooltip from overflowing bottom edge of it's container
    transition: transform 0ms linear 120ms;

    &--clicked {
      transform: translateY(0);
      pointer-events: auto;
      transition-delay: 0ms;
    }
  }

  &__animated-container {
    visibility: hidden;
    opacity: 0;
    transform: translateY(4px);
    transition: transform 120ms ease, opacity 120ms ease,
      visibility 0ms linear 120ms;
  }

  &--clicked &__animated-container,
  &__container--clicked &__animated-container {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0ms;
  }

  &__blurb-content {
    display: inline-block;
    overflow: hidden;
    padding: $g-unit;
    width: max-content;
    word-wrap: break-word;
  }

  &--no-icon {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
  }

  &--children {
    height: 100%;
    width: 100%;
  }

  &--clicked {
    opacity: 1;
  }
}

.Tooltip:hover {
  color: $color-ui-primary;
}

.Tooltip--clicked,
.Tooltip--clicked:hover {
  color: $color-ui-primary;
}

.Tooltip__icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  outline: none;

  &--no-icon {
    width: 100%;
    height: 100%;
  }
}

.Tooltip__icon svg {
  fill: currentColor;
  flex: 1;
  height: 100%;
}

.Tooltip--Warning .Tooltip__icon svg {
  fill: $color-ui-warn;
}

.Tooltip--Warning.Tooltip--clicked .Tooltip__icon svg {
  fill: $color-ui-warn;
}

.Tooltip__dimple {
  width: 12px;
  height: 32px;
  position: absolute;
  transform-origin: 0 0;
}

.Tooltip__dimple--up {
  transform: rotate(-90deg) translateY(-50%);
  left: 50%;
}

.Tooltip__dimple--down {
  transform: rotate(90deg) translateY(-50%);
  top: 100%;
  left: 50%;
}

.Tooltip__dimple--right {
  transform: rotate(0deg) translateY(-50%);
  top: 50%;
  left: 100%;
}

.Tooltip__dimple--left {
  transform: rotate(180deg) translateY(-50%);
  top: 50%;
}

.Tooltip--TL .Tooltip__dimple--left {
  top: $g-unit * 3;
}

.Tooltip--TR .Tooltip__dimple--right {
  top: 26px;
}

.Tooltip--BL .Tooltip__dimple--left {
  top: auto;
  bottom: 10px;
}

.Tooltip--BR .Tooltip__dimple--right {
  top: auto;
  bottom: 10px;
}

.Tooltip__link {
  display: block;
  font-size: $x-small-font-size;
  color: $color-white;
  border-radius: 6px;
  background-color: $color-grey-4;
  padding: 6px;
  margin-top: $g-unit;
  text-decoration: none;
  text-align: center;
  position: relative;
  transition: $transition-time all ease;
  line-height: normal;
}

.Tooltip__link:active {
  background-color: rgba(255, 255, 255, 0.32) !important;
}

.Tooltip__link:active .OpenIcon {
  color: $color-white;
  opacity: 1;
}

.Tooltip__link .OpenIcon {
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -6px;
  color: $color-grey-1;
  fill: currentColor;
  transition: $transition-time all ease;
}

// custom tooltip styles
.TooltipRequired,
.TooltipRequired:hover,
.TooltipRequired__icon svg {
  color: $color-ui-warn;
  fill: currentColor;
}

.Tooltip--copied {
  color: $color-grey-4;
  &.Tooltip--clicked {
    color: $color-ui-primary;
  }

  &.Tooltip__container {
    width: max-content;
    min-width: auto;
  }

  .Tooltip__container__blurb {
    min-height: auto;
    padding: 0 8px;
  }

  .Tooltip__dimple--up {
    left: 54%;
  }
}
