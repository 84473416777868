@import "~stylesheets/utilities/variables.scss";

.SettingsChannelsSunshineConversationsModal.SettingsChannelsSunshineConversationsModal {
  height: auto;
  min-height: 472px;
}

.SunshineConversations {
  &__Intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: $big-font-size;
      font-weight: $heavy-font-weight;
      line-height: $x-large-font-size;
      margin-bottom: $g-unit;
    }
    p {
      width: 472px;
      margin-bottom: 18px;
    }
    &__popout-link {
      display: block;
      text-decoration: none;
      font-size: $small-font-size;
      line-height: $medium-line-height;

      div {
        display: inline-block;
        margin-left: 8px;
      }
    }
    &__bottom#{&}__bottom {
      justify-content: space-between;
    }

    svg {
      width: 20px;
      vertical-align: bottom;
    }
  }

  &__Settings {
    &__zd-social {
      display: flex;
      justify-content: space-between;
    }
    &__zd-social__row {
      display: flex;
    }
    &__zd-social__row .InputRow {
      flex: 1;
    }
    &__zd-social__row .InputRow:first-child {
      margin-right: $g-unit;
    }
    &__bottom#{&}__bottom {
      justify-content: flex-end;
    }

    &__connected-app {
      border-bottom: 2ƒpx solid #e8e8eb;

      &__heading {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        border: 1px solid #e8e8eb;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(2, 14, 29, 0.08);
        border-radius: 6px;
        padding: 8px;

        .app-id {
          display: flex;
          .SvgIcon {
            width: 20px;
            height: 20px;
            fill: #39c379;
            margin-right: 9px;
          }
        }

        button,
        button:hover {
          font-size: 14px;
        }
      }
    }

    .Banner {
      font-size: 12px;
      line-height: 16px;
    }

    .InputRow {
      margin-top: 16px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #656568;
    }
  }
}
