@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.UserImage {
  border-radius: 50%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &__image {
    height: 100%;
    width: 100%;

    &--has-picture {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &--default-picture {
      background-position: center 0.1em;
      background-size: contain;
      background-color: $color-grey-2;
      background-repeat: no-repeat;
    }

    &--initials {
      text-transform: capitalize;
      font-size: 0.6em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
    }
  }
}
