@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";
@import "~stylesheets/utilities/mixins.scss";

.Variable {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flex;
  cursor: pointer;
  background-repeat: no-repeat;

  &--orphaned {
    padding-top: 0;
    padding-bottom: 0;
    color: $color-ui-bad !important;
    border: 1px solid $color-ui-bad;
    background-color: transparent !important;
    border-radius: 100px;

    &:active {
      background-color: $color-ui-bad !important;
      color: $color-white !important;
    }
  }

  &--choose-open-dialog {
    &#{&} {
      background-color: $color-grey-4;
      color: $color-grey-1;
    }
  }
  &--create-new {
    vertical-align: middle;
  }
  &--create-new &__inner {
    background-color: $color-grey-2;
  }

  &--left-icon &__inner {
    padding-left: 25px;
  }

  &--right-icon &__inner {
    padding-right: 23px;
  }

  .Tooltip {
    margin-left: $g-unit-half;
    color: $color-ui-warn;
    fill: currentColor;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__arrow-down {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    width: $g-unit;
  }

  &__left-icon {
    width: $g-unit-double;
    position: absolute;
    left: 6px;
    top: 2px;
  }

  &__inner--local &__no-name-text {
    color: rgba(0, 46, 102, 0.5);
  }

  &__inner--global &__no-name-text {
    color: rgba(0, 66, 21, 0.5);
  }

  &__inner {
    max-width: 100%;
    border-radius: 100px;
    padding: 0 10px;
    font-size: $small-font-size;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: $medium-font-weight;
    line-height: 18px;
    vertical-align: top;
    white-space: nowrap;

    & > span {
      font-size: $small-font-size;
    }

    &--global {
      color: $color-ui-variables-global-active;
      background-color: $color-ui-variables-global;

      &:active {
        color: $color-white;
        background-color: $color-ui-variables-global-active;
      }
    }

    &--local,
    &--internal {
      color: $color-ui-variables-local-active;
      background-color: $color-ui-variables-local;

      &:active {
        color: $color-white;
        background-color: $color-ui-variables-local-active;
      }
    }

    &--oauth {
      color: $color-ui-variables-authentication-active;
      padding-right: $g-unit;
      background-color: $color-ui-variables-authentication;

      &:active:not(.variableEntity--open) {
        color: $color-white;
        background-color: $color-ui-variables-authentication-active;
      }
    }

    &--sensitive,
    &--sensitive_meta {
      color: $color-ui-variables-sensitive-active;
      background-color: $color-ui-variables-sensitive;

      &:active {
        color: $color-white;
        background-color: $color-ui-variables-sensitive-active;
      }
    }

    &--meta,
    &--headers {
      color: $color-meta;
      background-color: $color-ui-variables-web;

      &:active {
        color: $color-white;
        background-color: $color-ui-variables-web-active;
      }
    }
  }

  &[role="presentation"] {
    pointer-events: none;
    cursor: none;
  }

  .Dialog {
    top: 100%;
    margin-top: $g-unit/2;
  }
}

.ResponseEditorEditableMessage__body__variables .Variable {
  padding: 2px;
}
