// Grid
$g-unit: 8px;
$g-unit-double: ($g-unit * 2);
$g-unit-triple: ($g-unit * 3);
$g-unit-half: ($g-unit / 2);
$g-unit-small: 6px;

// Fonts
$font-stack: -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI",
  "Segoe UI Emoji", "Segoe UI Symbol", Roboto, Helvetica, Arial, sans-serif;
$mono-space: Consolas, "Liberation Mono", Menlo, Courier, monospace;

$xx-small-font-size: 10px;
$x-small-font-size: 12px;
$small-font-size-alt: 13px;
$small-font-size: 14px;
$main-font-size: 16px;
$large-font-size: 18px;
$big-font-size: 24px;
$x-large-font-size: 28px;
$xx-large-font-size: 30px;

$main-font-weight: 400;
$medium-font-weight: 500;
$heavy-font-weight: 600;
$x-heavy-font-weight: 700;

// Container Breakpoints
$window-breakpoint: 1200px;

// Navigation Sidebar Fixed width
$sidebar-size: 220px;

// Inputs
$input-animation-time: 150ms;
$input-height: 36px;
$smallish-input-height: 28px;
$small-input-height: 24px;
$x-small-input-height: 20px;
$xx-small-input-height: 18px;

// Transitions
$transition-time: 180ms;
$slow-transition-time: 280ms;
$x-slow-transition-time: 400ms;

// Modals
$modal-max-height: 70vh;

// Other
$box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.18);
$big-border-radius: 12px;
$main-border-radius: $g-unit;
$small-border-radius: 6px;
$smaller-border-radius: 4px;
$smallest-border-radius: 2px;

// Icons
$icon-height: 24px;
$icon-width: 24px;
$small-icon-width: 20px;
$small-icon-height: 20px;

// Line Heights
$x-small-line-height: $small-font-size;
$small-line-height: $main-font-size;
$medium-line-height: 20px;
$large-line-height: $big-font-size;

$small-button-font-size: $small-font-size-alt;

// Media Breakpoints
$small-screen-height: 550px;

// Slate Editor
$slate-line-height: 18px;

// Z Indices
$z-index-search-results: 9998;
$z-index-select-results: 9998;
$z-index-options: 9998;
$z-index-list-wrapper: 9998;
$z-index-list-item-submenu: 9998;
$z-index-list-submenu-wrapper: 9998;
$z-index-filter-input-response-selector: 9998;
$z-index-dropdown: 9998;
$z-index-info-bubble: 9999;
$z-index-modal-mask: 10000;
$z-index-window-alert-mask: 10000;
$z-index-window-alert-container: 10001;
$z-index-modal-container: 10001;
$z-index-window-alert: 10002;
$z-index-hover-tooltip: 10002;
$z-index-response-selector-dropdown: 99999;
$z-index-multilingual-invalid-tooltip: 99999;
$z-index-responses-action-bar: 999999;
$z-index-responses-header: 999999;
$z-index-ada-embed-button: 999999;
$z-index-dialog: 999999;
$z-index-nav-bar: 1000000;
$z-index-modal: 9999999;
$z-index-dev-toolbar: 9999999;
$z-index-alert: 10000000;
$z-index-tooltip: 10000000;
