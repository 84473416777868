@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Modal {
  &__sidebar {
    flex: 0 0 144px;
    border-right: 1px solid $color-border;

    &__button {
      width: 100%;
      border-radius: 0px;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid $color-border !important;
      box-shadow: none;
      height: 48px;
      justify-content: flex-start !important;
      padding: 0 20px !important;
      white-space: nowrap;

      &__text {
        margin-left: inherit;
        justify-content: center;
        font-size: 15px;
      }

      &:active {
        background-color: $color-white !important;
      }

      &--active {
        background-color: $color-border;
      }
    }
  }
}
