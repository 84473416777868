@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.MultilingualInputLanguages {
  display: flex !important;
  height: 24px;

  &__languages {
    flex: 1;
    overflow-x: hidden;

    &__scroller {
      padding-bottom: $g-unit-double;
      overflow-x: scroll;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        $color-grey-1
      );
      pointer-events: none;
    }

    &__languages-list {
      display: flex;
    }

    &__language-button {
      color: $color-grey-4 !important;

      &--active {
        background-color: $color-grey-2 !important;
      }
    }
  }
}
