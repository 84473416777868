@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.WindowAlert {
  pointer-events: none;
  z-index: $z-index-window-alert !important;

  @media print {
    display: none;
  }

  &--active {
    pointer-events: all;
  }

  &--active &__mask {
    background-color: $color-mask;
    transition: background-color 0.2s;
  }

  &--active &__container {
    top: 50%;
    transition: top 0.2s;
  }

  &__mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-window-alert-mask;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
  }

  &__container {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-index-window-alert-container !important;
    width: 100%;
    max-width: 320px;
    top: 150%;
    transition: top 0.2s;

    &__close {
      position: absolute;
      top: $g-unit;
      right: $g-unit;
      z-index: 1;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__inner-container {
    overflow: hidden;
    background-color: $color-white;
    border-radius: $main-border-radius;
    box-shadow: $box-shadow;
    height: 100%;
    min-height: 160px;
    box-sizing: border-box;
    line-height: 20px;
    display: flex;
    flex-direction: column;

    &__image {
      border-radius: $g-unit-half;
      width: 100%;
      margin-bottom: $g-unit;
    }

    &__title {
      text-align: center;
      font-size: $small-font-size;
      color: $color-grey-4;
      height: ($g-unit * 6);
      line-height: ($g-unit * 6);
      border-bottom: 1px solid $color-border;
    }

    &__content {
      padding: $g-unit-double;
      text-align: center;
      flex: 1;
    }

    &__actions {
      text-align: center;
      border-top: 1px solid $color-border;
      padding: $g-unit;
    }
  }
}
