@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsPlatformsZendeskModal {
  &__label-tooltip,
  &__section-heading-tooltip {
    display: flex !important;
    & > span {
      flex: 1;
    }
  }

  &__section-heading {
    font-size: $big-font-size;
    font-weight: $heavy-font-weight;
  }

  &__section-row {
    margin-top: $g-unit-half;
  }

  &__section-row--flex {
    display: flex;
  }

  &__input-col {
    flex: 1;
    &:first-child {
      margin-right: $g-unit;
    }
  }

  &__add-agent {
    margin-top: $g-unit-half;
  }

  &__bottom {
    display: block !important;
    text-align: right;
  }

  .Modal__section__sub-section__title {
    margin-bottom: 0;
  }

  &__label-tooltip span,
  &__optional-label {
    font-size: $small-font-size;
  }

  &__label-tooltip span {
    line-height: $big-font-size;
    font-weight: $heavy-font-weight;
  }

  &__optional-label {
    line-height: $main-font-size;
    color: $color-grey-4;
    padding-bottom: $g-unit-half;
    display: inline-block;
  }

  &__agent-email-cell {
    display: inline-block;
    padding-right: 2 * $g-unit-double;
    padding-bottom: $g-unit;
  }

  &__agent-email-col {
    box-shadow: inset 0 -1px 0 0 $color-grey-2;
  }

  &__remove-button {
    opacity: 0;
    width: 3 * $g-unit;
    height: 3 * $g-unit;
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -4 * $g-unit-half;
    transition: all $transition-time ease;
    cursor: pointer;
    outline: none;
  }

  &__agent-email-cell:hover &__remove-button {
    opacity: 1;
  }

  .Button .Button__text {
    min-width: 64px;
  }

  &--modalClosed {
    background-color: $color-white;
    transition: all $transition-time ease;
  }
}

.b-settings-platforms-sms-modal {
  &__bottom {
    &__delete {
      margin-left: auto;
    }
  }
}
