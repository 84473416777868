@import "~stylesheets/utilities/variables.scss";

.ModalProfileSecurity {
  &__title {
    display: flex;
    align-items: center;
  }

  &__enable {
    &__inputs {
      display: flex;
      align-items: flex-end;

      &__container {
        flex: 1;
        margin-right: $g-unit;

        &--size-small {
          flex: 0.5;
        }
      }

      &__button {
        margin-top: $g-unit;
      }
    }

    &__validation {
      &__container {
        margin-top: $g-unit;
      }

      &__title {
        display: flex;
        align-items: center;
      }

      &__button {
        margin: $g-unit $g-unit 0 0;
      }
    }
  }
}
