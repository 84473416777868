@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SelectSearchDropdown {
  &__header-icon {
    margin-right: $g-unit-half;
    display: flex;
    align-items: center;
  }

  &__header-arrow {
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: $color-grey-3;
    right: 0px;
  }

  &__search-block {
    padding: $g-unit;
  }

  &__search-block-input {
    font-size: $small-font-size;
  }

  &__list-group {
    list-style: none;
    padding: $g-unit 0px;
    margin: 0px;
    overflow: auto;
    max-height: 35vh;
  }

  &__list-item {
    cursor: pointer;
    font-size: $small-font-size;
    width: 100%;
    border: none;
    border-radius: $main-border-radius;
    background: none;
    color: $color-black;
    text-align: left;
    line-height: 32px;
    padding: 0px 12px;
    margin: 2px $g-unit;
    outline: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;

    &:hover {
      background-color: $color-action-hover;
    }

    &--active {
      background-color: $color-teal-100;
    }
  }

  &__list-item-selected-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    pointer-events: none;
  }

  &__list-separator {
    width: 100%;
    border-bottom: 1px solid $color-grey-2;
    padding-top: $g-unit;
    margin-bottom: $g-unit;
  }

  &__search-no-results {
    text-align: center;
    font-size: $small-font-size;
    padding: $g-unit 0px;
  }

  &__warning-message {
    font-size: $x-small-font-size;
    color: $color-status-negative;
    margin-top: 4px;
  }
}
