@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ErrorPage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    height: $g-unit * 6;
    width: $g-unit * 6;
    margin-bottom: $g-unit-double;
    margin-top: -48px;
  }

  &__text {
    text-align: center;

    &__title {
      font-size: $big-font-size;
      margin-bottom: $g-unit-double;
    }

    &__description {
      color: $color-grey-4;
      font-size: $small-font-size;
      text-align: center;
    }
  }
}
