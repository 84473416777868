@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.DevToolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-black;
  color: $color-white;
  z-index: $z-index-dev-toolbar;
  box-shadow: $box-shadow;
  transform: translate3d(0, 100%, 0);
  transition: 200ms ease-in-out;
  outline: none;

  &--expanded {
    transform: translate3d(0, 0, 0);
  }

  &__shadow-thing {
    box-shadow: 0 0 10px $color-black inset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
  }

  &__toggle {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 14px);
    cursor: pointer;
    background: $color-black;
    padding: $g-unit;
    margin: 0;
    border: none;
    border-radius: $main-border-radius $main-border-radius 0 0;
    transition: 200ms ease-in-out;
    outline: none;
    box-shadow: $box-shadow;
    color: $color-white;
    font-size: $small-font-size;
    display: flex;
    align-items: center;

    &:hover {
      transform: translate(-50%, 0);
    }
  }

  &__toggle-icon {
    width: 18px;
    height: 16px;
    margin-right: -4px;
    display: none;
  }

  &--expanded &__toggle {
    transform: translate(-50%, 0);
  }

  &--expanded &__toggle-icon {
    display: block;
  }

  &__content {
    height: 300px;
    overflow: auto;
    padding: $g-unit-double;
    font-size: $small-font-size;
  }
}
