@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ResponseEditorLanguagesDialog {
  &__languages {
    margin-bottom: $g-unit;

    &__language-button {
      display: block !important;
      width: 100%;
      color: $color-black !important;
      padding: 0 $g-unit-half !important;

      &:active {
        background-color: $color-grey-2 !important;
      }
    }
  }

  &__link-block {
    text-align: center;
    font-size: $x-small-font-size !important;

    &__link {
      text-decoration: none;
      font-size: $x-small-font-size !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
