@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.LanguageButton {
  position: relative;
  margin-left: auto;

  &--hidden {
    display: none;
  }

  &__add {
    background-color: $color-grey-4 !important;
    color: $color-white;

    &:active {
      background-color: $color-black !important;
    }
  }

  &__add--color-white {
    background-color: white !important;
    color: black;

    &:active {
      background-color: $color-grey-2 !important;
    }
  }
}

.Dialog {
  &__title {
    text-align: center;
    font-size: 11px;
    line-height: $g-unit-double;
    color: $color-grey-4;
    font-weight: $medium-font-weight;
    margin-bottom: $g-unit-half;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
