@import "~stylesheets/utilities/variables.scss";

.g-form {
  &__block {
    display: block;
    padding-bottom: 3 * $g-unit;
    white-space: pre-wrap;

    &__label {
      color: $color-black;
      margin-bottom: $g-unit;
      font-size: $small-font-size;
      font-weight: $medium-font-weight;
    }

    &__description {
      margin: -$g-unit-half 0 $g-unit-half 0;
      font-size: $small-font-size;
      line-height: $medium-line-height;
      color: $color-grey-4;
      white-space: pre-wrap;

      &--below {
        margin-top: $g-unit-half;
      }

      &--no-bottom-margin {
        margin-bottom: 0;
      }

      strong {
        color: $color-grey-4-active;
        font-weight: $medium-font-weight;
      }
    }
  }
}
