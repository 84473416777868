@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.InputClearable {
  position: relative;

  &__input {
    padding-right: 2 * $g-unit-double !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &--invalid {
      border: 2px solid $color-ui-warn !important;
    }
  }

  &:hover &__icon-container {
    opacity: 1;
  }

  &__icon-container {
    opacity: 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: $g-unit;
    display: flex;
    align-items: center;
    transition: opacity $input-animation-time ease;
    cursor: pointer;
    color: $color-grey-4;

    &:active {
      color: $color-black;
    }

    &--type-chevron {
      pointer-events: none;
    }

    &__icon {
      height: 20px;
      width: 20px;
    }
  }
}
