@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.QuickReplyLabelInput {
  display: flex;
  align-items: center;
  margin-bottom: $g-unit-half;

  &:last-child {
    margin-bottom: 0px;
  }

  &__language-code {
    padding-left: $g-unit-half;

    &__label {
      color: $color-grey-4;
      font-size: $small-font-size;
      font-weight: $heavy-font-weight;
      text-transform: uppercase;
      width: $g-unit * 5;
    }
  }

  &__debounced-input {
    width: 482px !important;
  }

  &__action-container {
    display: block;
    position: absolute;
    left: 94%;
  }

  &__action {
    display: flex;
    align-items: center;

    &__button {
      display: none !important;
    }
  }

  &:hover &__action {
    &__button {
      display: flex !important;
    }
    &__language-code {
      display: none !important;
    }
  }

  &__input {
    font-size: $small-font-size !important;
  }
}
