@import "~stylesheets/utilities/variables.scss";

.SettingsPlatformsZendeskSupportModal.SettingsPlatformsZendeskSupportModal {
  height: auto;
}

.ZendeskSupport {
  &__Intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: $big-font-size;
      font-weight: $heavy-font-weight;
      line-height: $x-large-font-size;
      margin-bottom: $g-unit;
    }
    p {
      width: 472px;
      margin-bottom: 18px;
    }
    &__popout-link {
      display: block;
      text-decoration: none;
      font-size: $small-font-size;
      line-height: $medium-line-height;

      div {
        display: inline-block;
        margin-left: 8px;
      }
    }
    &__bottom#{&}__bottom {
      justify-content: space-between;
    }

    svg {
      width: 20px;
      vertical-align: bottom;
    }
  }

  &__Settings {
    &__zd-social {
      display: flex;
      justify-content: space-between;
    }
    &__zd-social__row {
      display: flex;
    }
    &__zd-social__row .InputRow {
      flex: 1;
    }
    &__zd-social__row .InputRow:first-child {
      margin-right: $g-unit;
    }
    &__zd-social__row button {
      align-self: flex-end;
    }
    &__bottom#{&}__bottom {
      justify-content: space-between;
    }

    .Banner {
      font-size: 12px;
      line-height: 16px;
    }

    .InputRow {
      margin-top: 16px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #656568;
    }
  }
}
