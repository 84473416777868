@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.MultilingualInputLanguagesDialog {
  fill: $color-text-grey;
  -webkit-text-fill-color: $color-text-grey;

  &__languages {
    margin-bottom: $g-unit;
    padding: $g-unit 0px;
    &__title {
      font-size: $x-small-font-size;
      color: $color-grey-3-active;
      padding-left: 12px;
      padding-bottom: 5px;
    }

    &__enabled-languages {
      margin-bottom: 8px;
    }

    &__language-button {
      width: 85%;
      height: $input-height !important;
      color: $color-black !important;
      border-radius: 0px !important;
      transition: background $transition-time ease;
      text-align: left;

      &__delete {
        right: 5px;
        opacity: 0;
        position: absolute;
        z-index: 1;
        background-color: transition;

        &:hover {
          opacity: 1;
          background-color: inherit;
        }
      }

      &__active,
      &__inactive,
      &__invalid,
      &__add {
        width: 22px;
        height: 22px;
        background-color: transition;
      }

      &--container {
        display: flex;
        align-items: center;
        height: $input-height;
        transition: background $transition-time ease;
        cursor: pointer;
        outline: none;

        &:hover {
          background-color: $color-grey-1;
        }
      }

      &--selected {
        fill: $color-ui-primary;
        -webkit-text-fill-color: $color-ui-primary;
      }

      &:active {
        background-color: $color-grey-2 !important;
      }

      &:hover {
        background-color: $color-grey-1;
      }
    }
  }

  &__description {
    padding: 0px 4px 4px 4px;
  }

  &__link-block {
    text-align: center;
    font-size: $x-small-font-size !important;

    &__link {
      display: block;
      padding-top: 5px;
      text-decoration: underline;
      font-size: $x-small-font-size !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
