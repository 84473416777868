.bounce-enter {
  opacity: 0.01;
  color: blue !important;
}

.bounce-enter.bounce-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.bounce-leave {
  opacity: 1;
}

.bounce-leave.bounce-leave-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}
