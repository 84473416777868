$nav-height: $g-unit * 6;

@mixin positionEmbed($property) {
  top: $nav-height !important;
  height: calc(100vh - #{$nav-height}) !important;
  z-index: $property !important;
}

#ada-button-frame,
#ada-intro-frame {
  z-index: 999999 !important;
  display: none !important;
}

#ada-chat-frame {
  border-radius: 12px 0 0 12px;
  z-index: 1000001 !important;
}

#ada-chat-frame.bt-new-navigation-temp {
  border-radius: 0 !important;
  @include positionEmbed(1000001);
}

#ada-drawer-mask-frame {
  z-index: 1000000 !important;
}

#ada-drawer-mask-frame.bt-new-navigation-temp {
  @include positionEmbed(1000000);
}

#ada-button-frame.builder-bot {
  min-height: 44px !important;
  min-width: 44px !important;
  bottom: $g-unit * 2 !important;
  right: $g-unit * 2 !important;
  display: block !important;
}

#ada-intro-frame.builder-bot {
  bottom: 0px !important;
  display: block !important;
}

#ada-chat-frame.builder-bot {
  z-index: 1000001 !important;
  height: 75vh !important;
  border-radius: $g-unit !important;
  right: 14px !important;
  bottom: 14px !important;
  top: unset !important;
}
