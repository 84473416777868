@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ModalWarning {
  &__sub-title {
    margin: 0 24px 24px 24px;
    font-weight: $heavy-font-weight;
    text-align: center;
  }

  &__message {
    display: block;
    text-align: center;
    margin: 0 24px 24px 24px;
    line-height: 23px;
  }
}
