@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Tag {
  display: inline-flex;
  border-radius: $small-border-radius;
  background-color: $color-tag-default;
  padding: 0 $g-unit;
  align-items: center;
  font-size: $small-font-size;
  color: $color-white;
  line-height: $small-font-size;
  height: $small-input-height;
  max-width: 100%;

  &--is-light {
    color: $color-black;
  }

  &--line {
    height: $g-unit;
    width: 40px;
  }

  &--small {
    height: $g-unit-double;
    font-weight: $medium-font-weight;
    font-size: $x-small-font-size;
    border-radius: 3px;
    &.Tag--icon {
      top: 2px;
    }
  }

  &__text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: $g-unit-double;

    svg {
      right: 2px;
      top: 2px;
      padding-left: 1px;
    }
  }

  &--icon {
    .Tag__text {
      top: -2px;
    }
    background-color: $color-grey-4-active;
  }

  &__button {
    // To fix Safari bug where button jiggles on hover with opacity transition
    transform: translate3d(0, 0, 0);
    margin: 0 -4px 0 4px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
