@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsSatisfactionModalPreview {
  position: relative;
  height: 100%;
  overflow: hidden;
  user-select: none;
  background-color: $color-grey-1;

  &__navigation {
    position: absolute;
    padding: 10px 0 20px;
    left: $g-unit-double;
    right: $g-unit-double;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__navigation-label {
    padding: 0 $g-unit;
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 $g-unit-double;
  }

  &__sample-message {
    border-radius: $big-border-radius;
    padding: $g-unit-double;
    margin-bottom: $g-unit * 3;

    &--client {
      background: $color-ui-primary-150;
      margin-left: auto;
    }

    &--bot {
      background: $color-grey-2;
      margin-right: auto;
    }
  }

  &__sample-message--bot + &__sample-message--bot {
    margin-top: -$g-unit-double;
  }

  &__input-block {
    padding: $g-unit $g-unit-double;
    display: flex;
    border-top: 1px solid $color-grey-1;
    margin: $g-unit-double (-$g-unit-double) 0 (-$g-unit-double);
  }

  &__input-block-input {
    flex-grow: 1;
    background-color: $color-grey-2;
    border-radius: $small-border-radius;
    height: $g-unit-double * 2;
  }

  &__input-block-send-button {
    width: $g-unit-double * 2;
    height: $g-unit-double * 2;
    color: $color-grey-2;
    margin-left: 2px;
  }

  &__survey-block {
    display: flex;
    flex-direction: column;
    background: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: $big-border-radius;
    padding: 40px $g-unit-double $g-unit * 3;
    min-height: 230px;
  }

  &__sample-message--bot + &__survey-block {
    margin-top: -$g-unit-double;
  }

  &__survey-block-message {
    text-align: center;
    font-size: $small-font-size;
    white-space: pre-wrap;
  }

  &__survey-block-buttons {
    position: absolute;
    top: 104px;
    left: $g-unit-double;
    right: $g-unit-double;
  }

  &__survey-block-buttons--yes-no {
    display: flex;
    justify-content: center;
  }

  &__survey-block-button {
    border-radius: 6px;
    color: $color-black;
    border: 1px solid $color-grey-1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: $medium-font-weight;
    padding: 11px 0;
  }

  &__survey-block-buttons--yes-no &__survey-block-button {
    min-width: 118px;
    margin: 0 $g-unit;
  }

  &__survey-block-scale {
    position: absolute;
    top: 104px;
    left: $g-unit-double;
    right: $g-unit-double;
  }

  &__survey-block-scale-grade {
    display: flex;
  }

  &__survey-block-scale-item {
    flex: 1 1 0;
  }

  &__survey-block-scale-item:not(:last-child) {
    margin-right: 4px;
  }

  &__survey-block-scale-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }

  &__survey-block-scale-label {
    font-size: $xx-small-font-size;
    color: $color-grey-4-active;
  }

  &__survey-block-comment-input {
    margin-top: $g-unit * 3;
  }

  &__survey-block-submit-button {
    margin: $g-unit-double auto auto;
    padding: $g-unit $g-unit-double;
    text-align: center;
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
    color: $color-ui-primary;
    border-radius: $small-border-radius;
    border: 1px solid $color-grey-1;
    box-shadow: 0 1px 2px rgba(2, 14, 29, 0.08);
  }
}
