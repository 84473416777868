@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

input,
textarea {
  outline: none;
  margin: 0;
}

.g-input {
  height: $input-height;
  border-radius: $small-border-radius;
  padding: 6px;
  width: 100%;
  transition: $input-animation-time ease;
  user-select: none;
  box-shadow: inset $color-border-form-field 0 0 0 1px;
  border: none;
  display: block;
  cursor: text;

  &__description {
    font-size: $small-font-size;
    color: $color-grey-4;
    white-space: pre-wrap;
  }

  &--mdia-url {
    &:focus + .ResponseEditorMediaUplaoder__focus-description {
      display: none;
    }
  }

  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &:focus#{&}:focus,
  &--focus#{&}--focus {
    /*
      Bump selector specificity so that focused border color takes precedence over
      invalid border color
    */
    // Use box-shadow instead of border to avoid animation issues where text size changes when input is selected
    box-shadow: inset $color-slate-700 0 0 0 2px;
    outline: 2px solid $color-action-primary-focus;
  }

  &--type-textarea {
    min-height: $g-unit * 7;
    display: block;
    overflow-y: auto;
    font-family: $font-stack;

    &.DebouncedInput {
      transition: border-color $input-animation-time ease;
      resize: none;
      max-height: 250px;
      line-height: $g-unit * 3;

      &.DebouncedInput--character-counter {
        padding: 2px 36px 2px 6px;
      }
    }
  }

  &--invalid,
  &--invalid:focus,
  &--focus#{&}--invalid {
    box-shadow: inset $color-status-negative 0 0 0 1px;
  }

  &--invalid#{&}--invalid-empty#{&}--focus,
  &--invalid#{&}--invalid-empty:focus {
    border-color: $color-ui-primary;
  }

  &::-webkit-input-placeholder {
    color: $color-grey-4;
  }

  &:disabled {
    color: $color-grey-4;
  }

  &--flash {
    animation-name: input-flash;
    animation-duration: 3s;
  }

  &--type-textarea#{&}--font-size-small {
    &.DebouncedInput {
      font-size: $small-font-size;
      line-height: 17px;
    }

    &.DebouncedInput--character-counter {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &__label {
    font-size: $small-font-size;
    line-height: $g-unit * 3;
    font-weight: $medium-font-weight;
    display: inline-block;

    &--no-description {
      margin-bottom: $g-unit;
    }
  }

  &--no-background {
    background: none !important;
  }
}

// Hide arrows on inputs with type "number"
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ResponseEditorEnhancedTextInput {
  &.g-input,
  &.g-input--type_textarea {
    padding: 2px 6px;

    &.CharacterCounter {
      padding: 2px 36px 2px 6px;
    }
  }
}

@keyframes input-flash {
  0% {
    background-color: $color-dynamic-grey;
  }
  5% {
    background-color: $color-input-flash;
  }
  30% {
    background-color: $color-input-flash;
  }
  100% {
    background-color: $color-dynamic-grey;
  }
}
