@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.MultilingualInputLanguageButton {
  position: relative;
  margin-left: auto;

  &--hidden {
    display: none;
  }

  &__add {
    background-color: $color-grey-4 !important;

    &:active {
      background-color: $color-black !important;
    }
  }

  &--invalid-tooltip {
    width: 18px;
    height: 18px;
    margin: 0;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -14px;
    z-index: $z-index-multilingual-invalid-tooltip;
  }

  &__current-language {
    box-shadow: none;
    border: none;
    font-size: $small-font-size;

    &--active {
      fill: $color-ui-primary;
      -webkit-text-fill-color: $color-ui-primary;
    }
  }

  &__dialog {
    padding: 0 !important;
    margin-top: 4px;
    right: -4px;
  }
}

.Dialog {
  &__title {
    text-align: center;
    font-size: 11px;
    line-height: $g-unit-double;
    color: $color-grey-4;
    font-weight: $medium-font-weight;
    margin-bottom: $g-unit-half;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
