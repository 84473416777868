@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

.SettingsSmartSearchModal {
  &-row {
    padding-top: 0px !important;
  }

  &__bottom {
    flex-direction: row-reverse;
  }

  &__row {
    margin-bottom: $g-unit;

    &--split {
      display: flex;

      > * {
        width: 100%;
      }

      > :not(:last-child) {
        margin-right: $g-unit;
      }
    }
  }
  &__divider {
    height: $g-unit;
    background: $color-grey-1;
    margin: 24px (-$g-unit-double);
    border: none;
  }
}

.LoginSplash {
  text-align: center;

  .Splash__icon {
    margin: 48px 0 0 0 !important;
  }
}
