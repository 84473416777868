@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

@font-face {
  font-family: "Roobert";
  src: url("static/fonts/Roobert-Regular.otf") format("opentype"),
    url("static/fonts/Roobert-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("static/fonts/Roobert-RegularItalic.woff2") format("woff2"),
    url("static/fonts/Roobert-RegularItalic.woff") format("woff"),
    url("static/fonts/Roobert-RegularItalic.otf") format("opentype"),
    url("static/fonts/Roobert-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

body {
  padding: 0;
  margin: 0;
  background-color: $color-white;
  font-size: $main-font-size;
}

* {
  font-size: inherit;
  overflow: visible;
  box-sizing: border-box;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

p,
p * {
  font-size: $main-font-size;
  white-space: pre-wrap;
  margin: 0;
}

a {
  color: $color-action-primary-text;
  font-size: inherit;
  text-decoration: none;
}

b,
strong {
  font-weight: $medium-font-weight;
  font-size: inherit;
}

svg {
  fill: currentColor;
}
