@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/mixins.scss";
@import "~stylesheets/utilities/colors.scss";

.g-tile {
  background-color: $color-white;
  width: calc(50% - 4px);
  min-height: 254px;
  margin-bottom: $g-unit;
  border: 1px solid $color-border;
  padding: 2.5 * $g-unit $g-unit-double;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: $main-border-radius;

  @include desktop-large() {
    width: calc(33% - 3px);
  }

  &__icon {
    height: $g-unit * 6;
    width: $g-unit * 6;
    margin: 0 auto $g-unit auto;
  }

  &__title {
    white-space: pre-wrap;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-size: $x-large-font-size;
    font-weight: $medium-font-weight;
  }

  &__description {
    font-size: $main-font-size;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 18px 0;
    margin-top: 7px;
    line-height: 1.5;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
  }

  &__small-icon {
    position: absolute;
    top: 22.5px;
    left: 19px;
  }

  &__learn-more {
    margin-bottom: $g-unit;
  }
}
