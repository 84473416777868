@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

.SettingsScheduleRow {
  &__row {
    display: flex;
    align-items: flex-start;
    padding: $g-unit 0;

    &__error {
      margin-top: 2px;
      line-height: 12px;
      font-size: $xx-small-font-size;
      color: $color-ui-warn;
      font-weight: $medium-font-weight;
    }
  }

  &__row:hover &__remove-row {
    opacity: 1 !important;
  }

  &__remove-row {
    opacity: 0;
    margin-left: auto;
    transition: opacity ease $transition-time;
  }

  &__all-day {
    display: flex;
    align-items: center;
    height: 32px;

    &__checkbox {
      margin-right: $g-unit;
    }
  }

  &__label {
    color: $color-grey-4;

    &__all-day {
      color: $color-grey-3;
    }
  }

  &__day-selector {
    margin-right: $g-unit-double;
  }

  &__date-selector {
    margin-right: $g-unit;
    width: 268px;
  }

  &__day-selector-buttons button {
    padding: 0px;
  }

  &__time-selector-container {
    margin-right: $g-unit-double;
    display: inline-flex;
    align-items: center;

    &--collapse {
      display: none;
    }
  }

  &__time-selector {
    width: 104px;

    &--from {
      margin-right: $g-unit;
    }

    &--to {
      margin-left: $g-unit;
    }
  }
}
