@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.InputRange {
  -webkit-appearance: none;
  margin: 10px 0;
  outline: none;
  padding: 0;
  height: $g-unit-half;
  background-color: #dedede;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, $color-action-secondary),
    color-stop(100%, $color-action-secondary)
  );
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    background: $color-grey-3;
    opacity: 0.5;
  }

  &::-webkit-slider-runnable-track {
    height: 5px;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    background: transparent;
    -webkit-appearance: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid $color-border;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: -9.5px;
    background: $color-white;
    cursor: pointer;
  }
}
