@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.LoginSplash {
  max-width: 340px;
  margin: auto;
  overflow: hidden;

  @media screen and (max-height: $small-screen-height) {
    height: 100%;
    margin: 0 auto;
  }

  &__action {
    color: $color-ui-primary;
  }

  &__header {
    font-weight: $heavy-font-weight;
    color: $color-black;
    white-space: pre-wrap;
    font-size: $big-font-size;
    line-height: $input-height;
    padding-bottom: 8px;
  }

  &__description {
    font-size: $small-font-size;
    color: $color-grey-4;
    line-height: $medium-line-height;
    padding-bottom: 24px;
  }
}
