@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ButtonGroupMultiSelect {
  display: inline-flex;

  &--invalid {
    z-index: 10 !important;
    border-radius: $small-border-radius;
    border-right: 1px rgba(0, 0, 0, 0) solid !important;
    box-shadow: 0px 0px 0px 1px $color-status-negative !important;
  }

  &__button {
    min-width: 38px;
    cursor: pointer;
    border-radius: 0;
    background-color: $color-white;
    outline: none;
    color: $color-black;
    box-shadow: none;
    border: 1px solid $color-border-button;
    z-index: 0;
    margin-right: -1px;

    &--invalid {
      z-index: 10 !important;
      border-radius: $smallest-border-radius;
      border: 1px rgba(0, 0, 0, 0) solid !important;
      box-shadow: 0px 0px 0px 1px $color-status-negative !important;
    }

    &--active,
    &:active {
      color: $color-text-main;
      background-color: $color-teal-100;
      border: 1px solid $color-action-secondary;
      z-index: 1;
    }

    &:first-child {
      border-radius: $small-border-radius 0 0 $small-border-radius;
    }

    &:last-child {
      border-radius: 0 $small-border-radius $small-border-radius 0;
    }
  }
}
