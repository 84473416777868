@import "~stylesheets/utilities/variables";

.InputRow {
  &__label-container {
    display: flex;
    line-height: $large-line-height;
    vertical-align: middle;
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
  }

  &__tooltip {
    transform: translateY(2px);
  }
}
