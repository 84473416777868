@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

.ModalEditRawJSON {
  &__container {
    padding: $g-unit-double;
  }

  &__enhanced-text-input {
    margin-top: $g-unit-double;
    height: 500px !important;
  }

  &__confirm-button {
    margin-top: $g-unit-double;
  }

  &__invalid-message {
    margin-top: $g-unit;
    font-size: $small-font-size;
    color: $color-ui-warn;
  }
}
