@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

#main {
  flex: 1;
  overflow: auto;
}

.app-container-old {
  flex-direction: column;
  background-color: $color-white;
}
