@import "~stylesheets/utilities/variables.scss";

.RadioButtons {
  display: inline;

  .RadioButton {
    margin-bottom: $g-unit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
