@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.SettingsAuthentication {
  height: 100%;
  width: 100%;
  overflow: auto;
  &__auth-btn {
    padding: $g-unit;
    height: auto;
  }
  &__description {
    display: flex;
    align-items: center;
    &__new-auth-btn-wrapper {
      align-self: flex-start;
    }
    &__wrapper {
      padding-bottom: 28px;
      padding-right: 28px;
      line-height: $medium-line-height;
    }
    &__title h5 {
      flex: 1;
      font-weight: $medium-font-weight;
      font-size: 3 * $g-unit;
      margin-bottom: 5px;
    }
    &__content {
      font-size: $small-font-size;
      color: $color-grey-4;
      p {
        font-size: $small-font-size;
        color: $color-grey-4;
      }
      &__link {
        color: $color-grey-4;
      }
    }
  }
  &__default {
    margin-top: $x-small-font-size;
    width: 100%;
    height: 520px;
    border: 1px solid $color-grey-2;
    box-sizing: border-box;
    border-radius: $small-border-radius;
    background-color: $color-grey-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__content {
      font-size: $main-font-size;
      color: $color-grey-3;
      text-align: center;
      z-index: 1;
      &__add {
        background-color: $color-grey-1;
        color: $color-action-primary-text;
        font-size: $main-font-size;
        text-decoration: underline;
        cursor: pointer;
        border: none;
        margin-top: 5px;
      }
    }
    &__image {
      margin-top: 20px;
    }
  }
  &__authentication-list {
    $controls-col-width: 195px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: $x-small-font-size;
    background: $color-white;
    border: 1px solid $color-grey-2;
    box-sizing: border-box;
    border-radius: $small-border-radius;
    padding: $x-small-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $g-unit;
    &__description {
      flex: 1;
      max-width: calc(100% - (#{$controls-col-width} + 5 * #{$g-unit}));
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__name {
        font-size: $small-font-size;
        color: $color-black;
      }
      &__redirectUri {
        padding-top: $g-unit-half;
        color: $color-grey-4;
      }
    }
    &__button-wrapper {
      display: flex;
      margin-left: 5 * $g-unit;
      justify-content: center;
      align-items: center;
      flex-basis: $controls-col-width;
    }
    &__transition-on-hover {
      display: flex;
      opacity: 0;
      transition: opacity $transition-time ease;

      button:first-of-type {
        margin-right: 4px;
      }
    }
    &:hover &__transition-on-hover {
      opacity: 1;
    }
    &__edit-button {
      margin-left: $g-unit-half;
      margin-right: $g-unit-half;
    }
    &__infoToolTip {
      margin-left: 6px;
      margin-right: $g-unit;
    }
  }
}
