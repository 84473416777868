@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SvgIcon {
  &__custom {
    -webkit-transition: all 120ms ease;
    transition: all 120ms ease;
    &:active {
      color: $color-black !important;
    }
  }

  &__fromSvgString {
    height: 44px;
    width: 44px;
  }

  &__fromSvgString-messageIcon {
    height: 20px;
    width: 20px;
  }

  &--disabled {
    color: $color-grey-2;
  }
}
