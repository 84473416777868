@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.DebouncedInput {
  font-size: 14px;

  &__character-counter {
    right: 7px;
    bottom: 6px;
    position: absolute;
    font-size: $xx-small-font-size;
    font-weight: $heavy-font-weight;
    color: $color-grey-4;

    &--invalid {
      color: $color-status-negative;
    }
  }

  &__warning-message {
    font-size: $x-small-font-size;
    color: $color-status-negative;
    margin-top: 4px;
  }

  &:disabled#{&}:disabled,
  &__disabled {
    background-color: $color-grey-1;
    border: 1px solid $color-grey-1;
    border-radius: $small-border-radius;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: unset;
    opacity: 0.5;
  }
  &::placeholder {
    font-style: italic;
    font-size: 14px;
  }
}
