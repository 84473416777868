@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/mixins.scss";

.VariableSearchSelect {
  &__variable-input {
    display: flex !important;
    align-items: center;
    overflow: hidden;
    padding: 2px !important;
    cursor: pointer !important;
    outline: none;

    &__disabled {
      opacity: 0.5;
    }

    .variableEntity--error {
      background: none;
    }

    &__icon-container {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: $g-unit-half;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      //Use rgba(255,255,255,0) instead of transparent to solve Safari rendering issue: https://stackoverflow.com/questions/11829410/css3-gradient-rendering-issues-from-transparent-to-white
      background: linear-gradient(
        to left,
        $color-white,
        $color-white 28px,
        rgba(255, 255, 255, 0)
      );

      &__invalid {
        color: $color-ui-warn;

        &:hover,
        &:active {
          color: $color-ui-warn-active;
        }
      }

      &__icon {
        height: 20px;
      }
    }
  }

  &__results {
    display: block;
    z-index: $z-index-search-results;
    position: absolute;
    background-color: $color-white;
    box-shadow: $box-shadow;
    border-radius: 4px;
    padding-top: $g-unit;
    overflow: hidden;

    &__search {
      margin: 0 $g-unit $g-unit $g-unit;
    }

    &__variables {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 160px;
      padding: 0;
      margin: 0;

      &__item {
        &__button {
          @include blank-button;
          width: 100%;
          height: 100%;
          padding: 8px;
          display: flex;
          cursor: pointer;

          &__variable-container {
            overflow: hidden;
          }
        }

        &__icon-container {
          display: flex;
          align-items: center;
          margin-left: auto;
          visibility: hidden;

          &--visible {
            visibility: visible;
          }

          &__icon {
            height: $g-unit-double;
          }
        }

        &--selected {
          background-color: $color-teal-100;

          &:after {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0),
              $color-teal-100 30px,
              $color-teal-100
            ) !important;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 40px;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            $color-white 30px,
            $color-white
          );
        }
      }
    }

    &__empty {
      padding: 0 $g-unit;
      color: $color-grey-4;
      height: 160px;
      line-height: 160px;
      text-align: center;
    }
  }

  &--small &__variable-input__icon-container__icon {
    height: $g-unit-double;
  }
}
