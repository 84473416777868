@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.PasswordGuidelines {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: $x-small-font-size;
    color: $color-black-2;
  }

  svg {
    margin: 0 6px 0 0;
  }
}
