@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/mixins.scss";

@mixin select-size($height) {
  padding-right: $height;
  height: $height;
  font-size: $small-font-size;
  line-height: 20px;
  border-radius: $small-border-radius;

  &__text {
    max-width: calc(100% - #{$g-unit + $height - 2px});
  }
}

.Select {
  cursor: pointer;
  position: relative;
  display: block;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &--disabled {
    pointer-events: none;
    cursor: default;
  }

  &--disabled &__select {
    border-color: $color-grey-1;
    &__text {
      color: $color-grey-3;
      background-color: $color-white;
    }
  }

  &--disabled &__icon-container__icon {
    fill: $color-grey-1 !important;
  }

  &--size-small &__select,
  &--size-x-small &__select {
    @include select-size($small-input-height);
  }

  &--size-x-small &__select {
    font-size: $x-small-font-size;
    padding: 0 ($g-unit * 3 + 2px) 0 ($g-unit - 2px);
  }

  &--size-small &__icon-container {
    width: $small-input-height;
  }

  &--size-x-small &__icon-container {
    width: $g-unit * 3;
  }

  &:focus-within &__select {
    border: 1px solid $color-black-rebrand;
  }

  &--size-small:focus-within &__select {
    padding: 0 ($small-input-height - 1) 0 7px !important;
  }

  &--size-x-small:focus-within &__select {
    padding: 0 ($g-unit * 3 + 1px) 0 ($g-unit - 3px);
  }

  &--invalid &__select {
    padding: 0 ($input-height - 1) 0 7px;
    border: 1px solid $color-status-negative;
  }

  &--size-small#{&}--invalid &__select {
    padding: 0 ($small-input-height - 1) 0 7px !important;
  }

  &__custom-button {
    width: 26px;
    height: 24px;
    fill: $color-grey-3;
    pointer-events: none;

    &--active {
      fill: $color-slate-700;
    }
  }

  &__select {
    background-color: $color-white;
    padding: 0 $g-unit;
    height: $input-height;
    border-radius: $main-border-radius;
    cursor: pointer;
    min-width: 100%;
    padding-right: $input-height;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:focus-within {
      border: 1px solid $color-black-rebrand;
      outline: 3px solid $color-action-primary-focus;
      padding: 0 ($input-height - 1) 0 7px;
    }

    &--placeholder {
      color: $color-grey-4;
    }

    &__placeholder-rich {
      display: flex;
      align-items: center;

      &__icon {
        height: 24px;
        width: 24px;
        margin: 0 8px 0 -4px;
      }

      &__label {
        display: flex;
        align-items: center;
        color: $color-text-grey;
        font-size: $small-font-size;
        font-weight: 500;
        pointer-events: none;
      }
    }

    &--no-border {
      border: 1px solid transparent;
    }

    &--dark {
      font-weight: $medium-font-weight;
    }

    &__hidden-options {
      &__option {
        visibility: hidden;
        height: 0;
        display: block;
      }
    }

    &__text {
      color: $color-black;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: calc(100% - #{$g-unit + 4 * $g-unit - 2px});
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: auto;

      &--placeholder {
        color: $color-grey-4;
      }

      &--relative#{&}--relative {
        display: inline-flex;
        position: relative;
        max-width: initial;
      }

      &--icon-visible {
        display: flex;
        align-items: center;

        svg {
          margin-right: $g-unit;
        }
        span {
          font-size: 14px;
        }
      }
    }

    &__icon {
      height: 20px;
      width: 20px;
    }
  }

  &__list-wrapper {
    position: absolute;
    z-index: $z-index-list-wrapper;
    left: 0;
  }

  &__list-sub-menu-wrapper {
    position: absolute;
    z-index: $z-index-list-submenu-wrapper;
    left: 100%;
  }

  &__list {
    list-style: none;
    display: block;
    margin: 0;
    background-color: $color-white;
    box-shadow: $box-shadow;
    border-radius: $main-border-radius;
    padding: $g-unit 0;
    opacity: 1;
    transition: opacity 1s;
    overflow-y: auto;
    overflow-x: hidden;
    position: unset;

    &--align-right {
      right: 0;
      left: initial;
    }

    &--sub-menu {
      position: absolute;
      top: -41px;
      z-index: $z-index-list-item-submenu;
    }

    &__item {
      color: $color-black;
      position: static;
      margin: 2px $g-unit;
      border-radius: $main-border-radius;

      &--no-sub-menu:hover,
      &--focus {
        background-color: $color-action-hover;
      }

      &--active {
        background-color: $color-teal-100;
        color: $color-text-main;
      }

      &--disabled {
        color: $color-grey-3-active;
        cursor: default;
        pointer-events: none;
        cursor: default;

        &:hover {
          background-color: $color-white;
        }
      }

      &--sub-header {
        cursor: default;
        font-size: $x-small-font-size;
        color: $color-grey-3-active;
        padding: 12px 12px 4px 12px;

        &:hover {
          background-color: $color-white;
        }
      }

      &--divider {
        cursor: default;
        background: $color-grey-2;
        width: 100%;
        height: 1px;
        padding: 0;
        margin: 10px 0;

        &:hover {
          cursor: default;
          background: $color-grey-2;
        }
      }

      &__button {
        @include blank-button;
        width: 100%;
        height: 100%;
        padding: $g-unit (4 * $g-unit) $g-unit 12px;
        display: flex;
        cursor: pointer;
        align-items: center;
        font-size: $small-font-size;
        white-space: nowrap;
        color: inherit;

        &__label {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          &__clear {
            color: $color-slate-700;
          }
        }

        &__option-icon {
          height: 20px;
          width: 20px;
          margin-right: $g-unit;
        }

        &__icon {
          height: 20px;
          position: absolute;
          right: $g-unit;
        }
      }
    }
  }

  &__icon-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $input-height;
    pointer-events: none;

    &__icon {
      height: 20px;
    }
  }

  &__warning-message {
    font-size: $x-small-font-size;
    color: $color-status-negative;
    margin-top: 4px;
  }
}
