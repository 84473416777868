@mixin desktop-large() {
  @media (min-width: $window-breakpoint) {
    @content;
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin blank-button {
  border: none;
  outline: none;
  background: none;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin truncate-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@function userIcon($fill) {
  @return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath d='M18.5979,46.5892c5.9084-1.9452,7.33-3.8562,7.4087-9.0845a9.7642,9.7642,0,0,1-1.5755-1.567,12.1636,12.1636,0,0,1-2.237-5.9669,1.1244,1.1244,0,0,1-.2782.0358c-1.0845,0-1.9055-2.6617-1.9055-4.5338s.5573-2.4411,1.0889-2.4411a3.025,3.025,0,0,1,.42.03,18.5491,18.5491,0,0,1-.5089-4.0409c0-7.0016,2.6669-11,11-11,3.9695,0,4.237,1.043,5,2.5a2.3056,2.3056,0,0,1,1.5-.5c2.1193,0,4.5,2.72,4.5,9a18.5479,18.5479,0,0,1-.5092,4.0409,3.0329,3.0329,0,0,1,.42-.03c.5316,0,1.0886.5693,1.0886,2.4411s-.8207,4.5338-1.9052,4.5338a1.1225,1.1225,0,0,1-.2782-.0358,12.1636,12.1636,0,0,1-2.237,5.9669,9.7685,9.7685,0,0,1-1.5758,1.567c.0787,5.2283,1.5,7.1393,7.4087,9.0845,5.5276,1.82,8.1353,3.091,8.5954,7.7709a1.5117,1.5117,0,0,1-1.5006,1.6608H11.5031A1.5117,1.5117,0,0,1,10.0025,54.36C10.4626,49.68,13.07,48.409,18.5979,46.5892Z' fill-rule='evenodd' fill='#{encodecolor($fill)}'/%3E%3C/svg%3E";
}

@function errorIcon($fill) {
  @return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath d='M55.0064,52.0079H9.0234a3.0009,3.0009,0,0,1-2.5655-4.5547L29.4494,9.4424a2.9977,2.9977,0,0,1,5.1309,0L57.5719,47.4532A3.0009,3.0009,0,0,1,55.0064,52.0079ZM32.0148,48.0207a3,3,0,1,0-3-3A3,3,0,0,0,32.0148,48.0207Zm1.9985-28H30.0344a1.01,1.01,0,0,0-1.0209,1.0526l.9147,17a1.0127,1.0127,0,0,0,1.021.9474h2.1493a1.013,1.013,0,0,0,1.0211-.9474l.9147-17A1.01,1.01,0,0,0,34.0133,20.0208Z' fill-rule='evenodd' fill='#{encodecolor($fill)}'/%3E%3C/svg%3E";
}

@function arrowDown($fill) {
  @return "data:image/svg+xml,%3Csvg fill='none' height='5' viewBox='0 0 8 5' width='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m.504723 0h6.990687c.28282.00348109.50871.197459.50453.433259-.00153.086564-.03459.170693-.09478.241235l-3.49542 4.146896c-.15727.18866-.46819.23529-.69446.10417-.04874-.02825-.09105-.06353-.12493-.10417l-3.4954716-4.146896c-.1639713-.192157-.1100609-.458758.1204116-.5954666.084634-.0502034.185575-.07776575.289433-.0790274z' fill-rule='evenodd' fill='#{encodecolor($fill)}' /%3E%3C/svg%3E";
}
