@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Root {
  height: 100vh;

  &__ada-embed {
    position: absolute;
    bottom: 0;
    right: $g-unit-half;
    z-index: $z-index-ada-embed-button;
  }
}
