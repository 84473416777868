@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Alerts {
  width: 100%;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-alert;
  transition: opacity $transition-time ease;
  pointer-events: none;
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.16) 100%
  );

  &--hidden {
    opacity: 0;
  }

  &__box {
    pointer-events: all;
    position: absolute;
    right: 100px;
    bottom: 15px;
    width: 390px;
    max-height: 120px;
    background-color: $color-white;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    border-radius: $main-border-radius;
    transition: all $transition-time ease;
    transform: translatey(0px);
    padding: $g-unit;
    display: flex;

    &--hidden {
      transform: translatey(120px);
    }

    &__icon-container {
      flex: 1;
      margin-right: 9px;

      &__icon {
        width: 100%;
      }
    }

    &__text {
      flex: 9;

      &__message {
        max-height: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-size: $small-font-size;
        margin-bottom: $g-unit;
        word-break: break-word;
      }
    }
  }
}
