@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/mixins.scss";

.DatePickerDropdown {
  margin-right: $g-unit;

  &__dropdown-container {
    display: flex;
    z-index: $z-index-dropdown;
    position: absolute;
    margin-top: 7px;
    left: 0;
    background-color: $color-white;
    box-shadow: $box-shadow;
    border-radius: $small-border-radius;
    padding: 0;
    opacity: 1;
    transition: opacity 1s;
  }

  &--align-right &__dropdown-container {
    left: auto;
    right: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $color-white;
    border-radius: $small-border-radius;
    border: 1px solid $color-grey-2;
    cursor: pointer;
    padding: 0 35px 0 11px;
    font: inherit;
    outline: inherit;
    color: $color-black;
    height: $g-unit * 4;
    box-shadow: 0 1px 2px 0 rgba(31, 34, 36, 0.04);
    width: 100%;

    &:active {
      background-color: $color-grey-1;
      border-color: transparent;
      box-shadow: 0 1px 2px 0 rgba(31, 34, 36, 0);
    }
  }

  &--open &__header {
    border-color: $color-ui-primary;
    box-shadow: 0 0 0 1px $color-ui-primary;
    background-color: $color-grey-1;
  }

  &__header-icon {
    margin-right: 9px;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }

  &__header-arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    pointer-events: none;
    color: $color-grey-4-active;
  }

  &__header-title-container {
    display: flex;
  }

  &__header-title {
    margin-top: -1px;
    display: flex;
    align-items: center;
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
  }

  &--disabled {
    color: $color-grey-2;
  }
}
