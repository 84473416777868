@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ModalEditTraining {
  &__content {
    &__input-container {
      &__input {
        min-height: 50px;
        line-height: $large-line-height;
      }
    }
  }
  &__bottom {
    &__button {
      &--left {
        margin-right: $g-unit;
      }
    }
  }
}
