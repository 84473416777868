@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsSatisfactionModal {
  &__content {
    flex: 1;
    display: flex;
    height: 0; // Fixes the flex bug in Chrome 72
  }

  &__settings {
    flex: 1 1 0;
    overflow: auto;
    padding: $g-unit * 4;
    z-index: 1;
  }

  &__settings-section {
    margin-bottom: $g-unit * 6;

    &--sub {
      margin-bottom: $g-unit * 3;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__settings-section-checkbox {
    float: right;
    position: relative;
    top: -4px;
    z-index: 1;
  }

  &__settings-section-title {
    font-size: $main-font-size;
    font-weight: $medium-font-weight;
    margin-bottom: $g-unit * 3;

    &--small {
      font-size: $small-font-size;
      margin-bottom: $g-unit-half;
    }
  }

  &__settings-section-description {
    font-size: $small-font-size;
    color: $color-grey-4;
    margin-bottom: $g-unit-double;
  }

  &__settings-text-input {
    margin-bottom: $g-unit * 2;
  }

  &__settings-text-input-label {
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
    line-height: $g-unit * 3;
  }

  &__scale-type-picker {
    display: flex;
    align-items: center;
    margin-top: $g-unit;
    margin-bottom: $g-unit;
  }

  &__scale-range-picker {
    display: flex;
    align-items: center;
    margin-top: $g-unit;
    margin-bottom: $g-unit;
  }

  &__scale-range-picker-input {
    flex-grow: 1;
  }

  &__scale-range-picker-value {
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
    width: 30px;
    text-align: right;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__scale-start-at-one-checkbox {
    font-size: $small-font-size;
  }

  &__settings-section-input-block:not(:last-child) {
    margin-bottom: $g-unit;
  }

  &__preview {
    flex: 1 1 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20px;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        $color-grey-1
      );
      pointer-events: none;
    }
  }

  &__controls {
    justify-content: flex-end;
    padding: $g-unit-double !important;
  }

  &__controls-button {
    margin-left: $g-unit;
    min-width: 120px;
  }
}
