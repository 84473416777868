@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

$INPUT_WIDTH: 374px;

.ModalProfile {
  &__details {
    &__user-info {
      display: flex;

      &__role {
        width: $INPUT_WIDTH;
      }
      &__inputs {
        width: $INPUT_WIDTH;
      }

      &__text {
        flex: 1;
        margin-left: $g-unit-double;
      }
    }

    &__passwords {
      font-size: $x-small-font-size;

      &__inputs {
        display: flex;
        flex-direction: column;
        width: $INPUT_WIDTH;

        &__input {
          flex: 1;
        }

        &__button {
          margin-right: $g-unit;
        }

        &__link {
          cursor: pointer;
        }
      }

      &__button-container {
        display: flex;
        align-items: center;
        margin-top: $g-unit;
      }
    }

    &__timezone {
      width: $INPUT_WIDTH;
    }

    &__description {
      font-size: $small-font-size;
      color: $color-grey-4;

      &__link {
        text-decoration: none;
      }
    }
  }

  &__bottom {
    &__logout {
      position: absolute;
      left: $g-unit-double;
    }
  }

  &__emails {
    &__section {
      display: flex;
      align-items: flex-start;

      &__text {
        margin-right: $g-unit-double;
      }
    }
  }

  &__email-preference {
    width: 50%;
  }

  &__label {
    margin-bottom: $g-unit-half;
  }

  .PasswordGuidelines svg {
    margin-right: 6px;
  }
}
