@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsPlatformsHandoffsModal {
  height: auto !important;

  &__bottom {
    display: block !important;
    text-align: right;
  }

  .input-row {
    padding-bottom: 0;
    margin-bottom: $g-unit !important;

    &__title {
      margin-bottom: 0;
      display: block;
      &__header {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .Modal__content {
    & > :last-child {
      margin-bottom: ($g-unit * 6) !important;
    }
  }

  &__invalid-message {
    font-size: $small-font-size;
    margin-top: $g-unit-half;
    color: $color-ui-warn;
  }
}
