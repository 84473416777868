@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

.SalesforceLAModal {
  &__modal {
    outline: 2px solid orange;
    transition: 0.45s max-height;
    max-height: $modal-max-height;

    &--intro {
      max-height: 340px;
    }
  }

  &__intro {
    margin: ($g-unit * 4) 0;
    text-align: center;

    p {
      font-weight: normal;
      font-size: $small-font-size;
      line-height: $medium-line-height;
      max-width: 400px;
      margin: 0 auto 18px;
    }

    a {
      font-size: $small-font-size;
      line-height: $medium-line-height;
      display: inline-block;
      margin: 0 auto;
      padding: 0 1em;
      text-decoration: none;

      svg {
        vertical-align: text-bottom;
        margin-right: $g-unit;
      }
    }
  }

  &__toast {
    color: $color-ui-primary;
    background: $color-ui-primary-100;
    border-radius: $small-border-radius;
    padding: $g-unit $g-unit $g-unit 36px;
    font-size: $x-small-font-size;
    position: relative;
    line-height: $large-line-height;
    margin-bottom: $g-unit;

    svg {
      color: $color-ui-primary;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10.5px;
    }
  }

  &__row {
    margin-bottom: $g-unit;

    &--split {
      display: flex;
      width: 100%;
    }
  }

  &__cell {
    flex: 0.5;

    &:first-child {
      margin-right: $g-unit;
    }
  }

  &__divider {
    height: $g-unit;
    background: $color-grey-1;
    margin: 24px (-$g-unit-double);
    border: none;
  }

  &__link-block {
    h6 {
      font-weight: $heavy-font-weight;
      font-size: 16px;
      margin-bottom: $g-unit-half;
    }

    p {
      font-size: $small-font-size;
      line-height: $medium-line-height;
      color: $color-grey-4;
      margin-bottom: $g-unit-double;
    }
  }

  &__actions {
    display: flex;
    width: 100%;
  }

  &__cancel-button {
    margin-right: auto;
  }

  &__connected-status {
    border: 1px solid $color-grey-2;
    border-radius: $small-border-radius;
    padding: $g-unit-double $g-unit $g-unit-double 48px;
    position: absolute;
    display: flex;
    width: 100%;
    line-height: $medium-line-height;
    margin-bottom: $g-unit-double;

    svg {
      position: absolute;
      left: $g-unit-double;
      top: 50%;
      transform: translateY(-50%);
      color: $color-ui-good;
    }
  }

  &__disconnect {
    margin-left: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    color: $color-ui-primary;
    border: none;
    cursor: pointer;
  }
}
