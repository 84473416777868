@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SessionTimeoutModal {
  &__action {
    cursor: pointer;
    &:hover {
      background-color: $color-grey-1;
    }
  }

  &__details {
    text-align: center;
    font-size: $main-font-size;
    line-height: $medium-line-height;
  }
}
