@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Checkbox {
  width: auto;
  cursor: pointer;
  user-select: none;
  align-items: center;
  outline: none;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__box,
  &__label {
    height: $small-input-height;
  }

  &--small &__box,
  &--small &__label {
    height: $xx-small-input-height;
  }

  &__box {
    &--type-switch {
      position: relative;
      display: inline-block;
      width: $g-unit * 6;
      border-radius: $big-border-radius;
      background-color: $color-grey-2;
      transition: 0.2s;

      &:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 0;
        margin: 1px;
        background-color: $color-white;
        border-radius: 50%;
        transition: 0.2s;
      }

      &:active:before {
        background-color: $color-grey-1;
      }

      &:active {
        background-color: $color-grey-3-active;
      }

      &--checked {
        background-color: $color-ui-good;

        &:before {
          transform: translateX($g-unit * 3);
        }

        &:active {
          background-color: $color-ui-good-active;
        }
      }
    }

    &--type-box {
      width: $small-input-height;
      border-radius: 6px;
      background-color: $color-white;
      border: 2px solid $color-border-form-field;

      &:active {
        background-color: $color-grey-1;
        border: 1px solid $color-grey-1;
      }

      &--checked {
        background: linear-gradient(
          180deg,
          $color-black-rebrand 0%,
          darken($color-black-rebrand, 4) 100%
        );
        -webkit-print-color-adjust: exact; // This is required to show bg color in Chrome's printed view

        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__icon {
    height: 100%;
    color: $color-white;
    position: absolute;
    top: 0;
  }

  &--small &__box {
    &--type-box {
      width: $xx-small-input-height;
      border-radius: 3px;
    }
  }

  &__label {
    color: $color-black;
    margin-left: 10px;
    display: flex;
    align-items: center;
    line-height: 0;
    font-size: inherit;
    cursor: inherit;
    white-space: nowrap;
  }

  &--theme-dark &__label {
    color: $color-white;
  }

  &--small &__label {
    font-size: $small-font-size;
  }

  &--label-left &__label {
    order: -1;
    margin-left: 0;
    margin-right: $g-unit;
    min-width: 0;
    flex-grow: 1;
  }
}
