@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.DatePicker {
  background-color: transparent;
  width: 250px;
  padding: 8px;
  user-select: none;

  &__selectors {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__selectors-button {
    background: none;
    border: none;
    font: inherit;
    outline: inherit;
    box-shadow: none;

    &:disabled {
      opacity: 0;
      visibility: hidden;
      transition-duration: 0ms;
    }
  }

  &__selectors-button svg {
    width: 18px;
    height: 18px;
  }

  &__selectors-dates {
    flex-grow: 1;
  }

  &__selectors-dates-month {
    display: flex;
    cursor: pointer;
  }

  &__selectors-dates-month:hover {
    opacity: 1;
  }

  &__selectors-dates-month-title {
    // remove default button style
    background: none;
    border: none;
    font: inherit;
    outline: inherit;

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;
    padding: 1px 0 0 1px;
  }

  &__dates-names {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
  }

  &__dates-names-name {
    flex: 1;
    min-width: calc(1 / 7 * 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-4;
    font-size: $x-small-font-size;
  }

  &__dates-date {
    // remove default button style
    background: none;
    font: inherit;
    outline: inherit;

    flex: 1 1 auto;
    width: calc(1 / 7 * 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
    font-size: $x-small-font-size;
    border-radius: $smaller-border-radius;
    border: 1px solid white;

    &:hover:not(&--disabled) {
      background-color: $color-ui-primary-200;
    }

    &:focus {
      outline: 0;
    }

    &--active {
      background-color: $color-ui-primary !important;
      border: 1px solid $color-ui-primary-active;
      color: white;
      padding: 7px;
    }

    &--hover-active {
      background-color: $color-ui-primary-100;
    }

    &--hover-active:hover {
      background-color: $color-ui-primary-200;
    }

    &--disabled {
      flex: 1 1 auto;
      width: calc(1 / 7 * 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      font-size: $x-small-font-size;
      border-radius: $smaller-border-radius;
      border: 1px solid white;
      color: $color-grey-3;
      cursor: default;

      &:focus {
        outline: 0;
      }
    }
  }

  &__dates-date-placeholder {
    border-color: transparent;
    height: 0;
    padding: 0;
    pointer-events: none;
  }
}
