@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.ModalBulkAddTraining {
  max-height: 410px;

  &__bulk-upload {
    position: relative;
  }

  &__overlimit-warning-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__bulk-upload-input {
    min-height: 180px;
    margin-bottom: 6px;
    resize: none;
    line-height: $large-line-height;
  }

  &__input-label {
    margin-bottom: 6px;
    color: $color-black;
  }

  &__hint {
    color: $color-grey-4;
    font-size: $x-small-font-size;
  }

  &__cancel-button {
    margin-right: $g-unit;
  }

  &__character-counter {
    right: 10px;
    bottom: 8px;
    position: absolute;
    font-size: $xx-small-font-size;
    font-weight: $heavy-font-weight;
    color: $color-grey-4;

    &--invalid {
      color: $color-ui-warn;
    }
  }
}
