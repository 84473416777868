@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

html {
  color: $color-text-grey;
  font-size: $main-font-size;
  font-family: $font-stack;
}

button {
  // Chrome was overriding button styles without this
  font-family: $font-stack;
}

.g-highlight {
  font-size: inherit;
  background-color: $color-ui-highlight;

  &--colour-blue {
    background-color: $color-blue-highlight;
  }
}

.g-variable {
  background-color: $color-grey-2;
  font-size: $x-small-font-size;
  display: inline-block;
  border-radius: 100px;
  padding: 0px $g-unit;
  line-height: $g-unit-double;
  height: $main-font-size;
  font-weight: $medium-font-weight;
}
