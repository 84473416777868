@import "~stylesheets/utilities/variables.scss";

.g-view {
  flex: 1;
  white-space: nowrap;
  display: flex;
  overflow: hidden;

  &__empty,
  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $large-font-size;
    color: $color-grey-4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &__icon {
      height: 40px;
    }

    h1 {
      font-size: $small-font-size;
    }
  }
}
