@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsPlatformsOracleModal {
  height: auto !important;

  &__label-tooltip,
  &__section-heading-tooltip {
    display: flex !important;
    & > span {
      flex: 1;
    }
  }

  &__optional-label {
    font-size: $small-font-size;
    line-height: $main-font-size;
    color: $color-grey-4;
    padding-bottom: $g-unit-half;
    display: inline-block;
    font-weight: normal;
  }

  &__bottom {
    display: block !important;
    text-align: right;
  }

  &__multi {
    display: flex;
    & > :only-child {
      flex: 0.5;
    }
  }

  .Modal__section {
    padding-bottom: 0;
    margin-bottom: $g-unit !important;
  }

  .Modal__content {
    & > :last-child {
      margin-bottom: ($g-unit * 6) !important;
    }
  }

  .Modal__section__sub-section {
    &__title {
      margin-bottom: 0;
      display: block;
      &__header {
        display: flex;
        justify-content: space-between;
      }
    }

    &__description {
      font-size: $small-font-size;
      font-weight: normal;
    }
  }

  &__remove-button {
    opacity: 0;
    width: 3 * $g-unit;
    height: 3 * $g-unit;
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -4 * $g-unit-half;
    transition: all $transition-time ease;
    cursor: pointer;
    outline: none;
  }

  &--modalClosed {
    background-color: $color-white;
    transition: transform $transition-time ease;
  }
}
