@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/mixins.scss";
@import "~stylesheets/utilities/colors.scss";

.g-side {
  display: flex;
  width: 100%;
  max-width: 340px;
  flex-direction: column;
  overflow: visible;
  background-color: $color-white;
  border-right: 1px solid $color-border;
  -webkit-user-select: none;
  flex-shrink: 0;

  &__menu {
    border-bottom: 1px solid $color-border;
    width: 100%;
    padding: $g-unit;

    &__filter {
      display: flex;
      align-items: center;

      input.search {
        display: inline-block;
        width: auto;
        flex-grow: 1;
        margin-right: 5px;
      }
    }
  }

  &__list {
    will-change: transform;
    transform: translate3d(0, 0, 0);
    overflow: auto;
    width: 100%;

    &__item {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 46px;
      padding: $g-unit;
      white-space: normal;
      cursor: pointer;
      text-decoration: none;
      color: $color-text-grey;
      border-radius: $main-border-radius;
      margin: $g-unit;

      &--selected {
        background-color: $color-teal-100;
      }

      &:hover {
        background-color: $color-action-hover;
      }

      &__label {
        z-index: 0;
        font-weight: $medium-font-weight;
        pointer-events: auto;

        &--light {
          color: $color-grey-4;
        }
      }

      &__description {
        color: $color-grey-4;
        font-size: $small-font-size;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        max-height: 55px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .tags {
        &__tag {
          cursor: pointer;
        }
      }
    }
  }

  @media print {
    display: none !important;
  }
}
