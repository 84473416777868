.SettingsSmartSearchModal {
  &-row {
    padding-top: 0px !important;
  }

  &__bottom {
    flex-direction: row-reverse;
  }
}

.LoginSplash {
  text-align: center;

  .Splash__icon {
    margin: 48px 0 0 0 !important;
  }
}
