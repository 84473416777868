@import "~stylesheets/utilities/variables.scss";

.ModalZendeskTicketingConfig {
  &__section {
    &__input-container {
      margin-bottom: $g-unit;

      &__input {
        flex: 1;
        margin-bottom: $g-unit;

        input {
          margin-bottom: $g-unit;
        }

        .g-input__label {
          flex: 1;
          display: flex !important;
          align-items: center;
          justify-content: space-between;

          &__icon {
            cursor: pointer;
            height: $g-unit * 2.5;
          }
        }

        &__subdomain {
          font-size: $small-font-size;
        }

        &:first-child {
          margin-right: $g-unit;
        }

        &__description {
          margin-top: $g-unit-half;
        }
      }
      &__row {
        display: flex;
      }
    }
  }
}
