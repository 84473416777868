@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.NumberedSteps {
  &__block {
    padding-left: $g-unit * 3;
  }

  &__step {
    margin-left: 12px;
    padding-bottom: $g-unit-double;
  }
  &__step:last-child {
    border-left: 0;
  }

  &__step-number,
  &__checkmark {
    position: absolute;
    width: $icon-width;
    height: $icon-height;
    left: -16px;
    top: 0px;
    border: 4px solid white;
    z-index: 9;
  }

  &__step-number {
    background: $color-grey-3;
    color: $color-white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $small-font-size;
    font-weight: $heavy-font-weight;
  }

  &__checkmark {
    background: $color-white;
    border: 0;
  }

  &__step--active &__step-number {
    background: $color-ui-primary;
  }

  .SettingsPlatformsZendeskModal__section-heading {
    color: $color-grey-3;
  }

  .SettingsPlatformsZendeskModal__section-row {
    display: none;
  }

  &__step--active,
  &__step--complete {
    .SettingsPlatformsZendeskModal__section-heading {
      color: $color-black;
    }

    .SettingsPlatformsZendeskModal__section-row {
      display: block;
    }

    .SettingsPlatformsZendeskModal__section-row--flex {
      display: flex;
    }
  }
}
