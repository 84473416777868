@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ResponseStatus {
  background-color: $color-grey-3;
  font-size: $x-small-font-size;
  font-weight: $x-heavy-font-weight;
  color: $color-white;
  text-align: center;
  border-radius: 3px;
  padding: 1px 6px;

  &--on {
    background-color: $color-ui-good;
  }

  &--small {
    display: flex;
    align-items: center;
    padding-left: $g-unit;
    background: none;
  }

  &--on#{&}--small {
    display: none;
  }
}
