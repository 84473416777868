@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.MultiLangAnswerLabels {
  position: relative;

  &__header {
    display: grid;
    grid-template-columns: [label] 1fr [button] 80px;
    grid-template-rows: auto;
    margin-bottom: $g-unit-half;
    height: 24px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__language-buttons {
    display: block;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  &__tooltip {
    display: block;
    margin-top: 2px;
  }

  &__description {
    margin-top: $g-unit-half;
    color: $color-grey-4;
    font-size: $x-small-font-size;
    margin-bottom: $g-unit;
  }

  &__headline {
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
    height: 24px;
    line-height: 24px;
  }
}
