@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.CheckboxPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__heading {
    color: $color-grey-4;
    font-size: $main-font-size;
  }

  &__description {
    margin-top: 0;
    line-height: 1.43;

    a {
      color: $color-grey-4;
    }
  }

  &__checkbox {
    align-self: baseline;
    float: right;
    vertical-align: bottom;
  }

  &__header {
    margin-bottom: $g-unit;
    color: $color-black;
    font-weight: $medium-font-weight;
  }

  &__infoTooltip {
    vertical-align: top;
    flex-grow: 1;
    margin-top: -2px;
  }
}
