@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.SettingsPlatformsChatModal {
  &__fake-text-input {
    background-color: $color-grey-1;
    border-radius: $main-border-radius;
    padding: $g-unit;
    position: relative;
    word-break: break-all;

    &__comment {
      color: $color-grey-4;
    }

    &__code-snippet {
      white-space: pre-line;

      &__tabbed {
        padding-left: 2em;
      }

      &.m-top {
        margin-bottom: $g-unit-double;
      }
    }

    &__copy {
      position: absolute;
      bottom: $g-unit;
      right: $g-unit;
      font-size: $small-font-size;
      color: $color-ui-primary;
      cursor: pointer;
    }
  }

  &__range-input {
    -webkit-appearance: none;
    height: 44px;
    width: 66%;
    background-color: transparent;

    &:after {
      content: attr(data-value) "%";
      position: absolute;
      top: 12px;
      right: -50px;
      padding-left: 10px;
      color: $color-grey-4;
    }
  }

  &__privacy-section {
    &__input {
      display: flex;
      margin-bottom: $g-unit;

      &__hidden {
        display: none !important;
      }
    }
  }

  &__select {
    display: inline-block;
    height: auto;
  }

  &__bottom {
    &__delete {
      margin-left: auto;
    }
  }

  &__input-container {
    display: flex;
    margin-bottom: $g-unit;
  }

  &__section {
    &__sub-section {
      width: 50%;
      display: inline-table;
      margin-bottom: $g-unit;

      &:nth-child(2n) {
        width: calc(50% - 8px);
        margin-right: $g-unit !important;
      }

      &__title {
        flex: 1;
        margin-bottom: 0 !important;
      }
    }
  }
}
