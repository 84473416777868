.g-stats {
  &__canvas {
    padding: 12px;
    margin: 0 auto;
    max-width: 590px;
    width: 100%;

    &__chart {
      width: 100%;
      height: 300px;
      margin: 1em 0;
    }
  }
}
