@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.MultilingualInput {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: $small-font-size;
    font-weight: $medium-font-weight;
    line-height: $g-unit * 3;
  }
}
