@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.SettingsAuthenticationModal {
  font-size: $small-font-size;
  &__optional-label {
    color: $color-grey-3-active;
  }
  &__uploaded-file-block {
    background: $color-white;
    border: 1px solid $color-grey-2;
    box-sizing: border-box;
    border-radius: $small-border-radius;
    padding: $x-small-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    &__name {
      font-size: $small-font-size;
      color: $color-black;
      flex: 1;
      margin-left: $x-small-font-size;
    }
  }
  &__certificate-upload {
    &__wrapper {
      display: inline-block;
      margin-top: $g-unit-half;
    }
    &__input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
  &__scope-title {
    display: flex !important;
    align-items: center;
    padding-bottom: 1px;
    border-bottom: 1px solid $color-grey-2;
    &__wapper {
      flex: 1;
    }
  }
  &__label-optional {
    color: $color-grey-2;
    flex: 1;
  }
  &__input-block#{&}__input-block {
    padding-bottom: $g-unit-double;
    &--invalid {
      padding-bottom: $g-unit;
    }
    &:last-child {
      padding: 0;
    }
  }
  &__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__warning {
    color: $color-ui-warn;
    font-size: $x-small-font-size;
    line-height: $g-unit-double;
  }
  &__name-type {
    display: flex !important;
    align-items: center;
    &__Name {
      width: 68%;
      flex: 1;
    }
    &__Type {
      width: 28%;
      padding-left: $g-unit;
    }
  }
  &__content {
    max-height: $modal-max-height;
    margin-right: $g-unit;
  }
  &__button-save {
    position: absolute;
    right: $g-unit;
  }
  &__button-add-scope {
    width: 3 * $g-unit;
    height: 3 * $g-unit;
  }
  &__scope-list {
    &__scope-row {
      display: flex;
      width: 100%;
      padding-bottom: 1px;
      border-bottom: 1px solid $color-grey-2;
      .SettingsAuthenticationModal__button-remove-scope {
        visibility: hidden;
      }
      &:hover .SettingsAuthenticationModal__button-remove-scope {
        visibility: visible;
      }
      &:hover .SettingsAuthenticationModal__button-remove-scope--first {
        visibility: hidden;
        pointer-events: none;
      }
      &__row-item {
        padding-top: 1px;
        flex: 1;
      }
    }
  }
  &__bottom {
    justify-content: space-between !important;
  }
}

.SettingsAuthenticationModal__notice {
  &__content {
    color: $color-notice-foreground;
    font-size: $small-font-size-alt;
    left: 3 * $g-unit;
    align-items: center;
    display: flex !important;
    width: 100%;
    padding: $g-unit-double;
    &__link {
      color: $color-notice-foreground;
    }
    &__dismiss {
      color: $color-notice-foreground;
      background-color: $color-notice-background;
      cursor: pointer;
      position: absolute;
      right: 35px;
      border: none;
    }
    &__icon {
      position: absolute;
      left: 10px;
      margin-top: $small-font-size;
      color: $color-notice-dismiss;
    }
  }
}
