@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.Button {
  base: &;
  margin: 0;
  background-color: $color-action-primary;
  color: $color-black-rebrand;
  border-radius: $small-border-radius;
  height: $input-height;
  min-width: $input-height;
  outline: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  padding: 0 $g-unit;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: $heavy-font-weight;
  flex-shrink: 0;
  font-family: $font-stack;
  font-size: $small-button-font-size;
  transition: all $transition-time ease;
  line-height: 30px;
  box-shadow: 0 1px 2px 0 rgba(2, 14, 29, 0.08);
  border: solid 1px rgba(16, 24, 32, 0.06);

  &:active {
    background-color: $color-gold-400;
    box-shadow: 0 1px 2px 0 rgba(2, 14, 29, 0);
    border-color: transparent;
  }

  &__text {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    pointer-events: none;
  }

  &--disabled {
    background-color: $color-background-disabled;
    pointer-events: none;
    box-shadow: none;
    color: $color-action-disabled;
    cursor: not-allowed;
    user-select: none;
    span,
    svg {
      color: $color-action-disabled;
    }
    svg {
      fill: $color-action-disabled;
    }
    opacity: 0.5;
  }

  &--disabled#{&}--disabled-flat {
    background-color: $color-grey-3;
    color: $color-white;
    border-color: transparent;
    span {
      color: $color-white;
    }
  }

  &--light,
  &--light-primary {
    background-color: $color-white;
    box-shadow: 0 1px 2px 0 rgba(31, 34, 36, 0.04);
    border: 1px solid $color-border-button;
    border-radius: $small-border-radius;
    &:active {
      background-color: $color-slate-200;
      border-color: transparent;
      box-shadow: 0 1px 2px 0 rgba(31, 34, 36, 0);
    }
  }

  &--light {
    color: $color-text-grey;

    span,
    svg {
      color: inherit;
    }
    svg {
      fill: $color-text-grey;
    }
  }

  &--light-primary {
    span,
    svg {
      color: $color-ui-primary;
    }
    svg {
      fill: $color-ui-primary;
    }
  }

  &--light#{&}--disabled,
  &--light-primary#{&}--disabled {
    box-shadow: none;

    span,
    svg {
      color: $color-text-light !important; // !important needed to override the tint element property
    }
  }

  &--clear {
    background-color: transparent;
    color: $color-text-grey;
    box-shadow: none;
    border-color: transparent;
    &:active {
      background-color: transparent;
    }
  }

  &--glyph#{&}--glyph {
    align-items: center;
    justify-content: center;
    padding-left: $g-unit;

    svg {
      fill: currentColor;
      width: $g-unit * 3;
      height: $g-unit * 3;
      margin: auto;
    }

    &--large-icon svg {
      width: $g-unit * 3;
      height: $g-unit * 3;
    }
  }

  &--no-text#{&}--no-text {
    padding: 0;
  }

  &--small {
    height: $small-input-height;
    min-width: $small-input-height;
    font-size: $small-button-font-size;
    line-height: $small-button-font-size;
    border-radius: $small-border-radius;

    svg {
      height: 18px !important;
      width: 18px !important;
    }
  }

  &--x-small {
    height: $small-input-height;
    min-width: $small-input-height;
    font-size: $small-button-font-size;
    line-height: $small-button-font-size;
    border-radius: $small-border-radius;

    svg {
      height: $g-unit-double;
      width: $g-unit-double;
    }
  }

  &--text &__text {
    margin: auto;
    justify-content: center;
  }

  &--text &__text--left {
    margin: unset;
    justify-content: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-align: left;
  }

  &--text#{&}--glyph,
  &--text#{&}--loading {
    svg {
      margin: 0 $g-unit 0 0;
    }
  }

  &--stretched {
    width: 100%;
  }

  .secondIconGroupRight {
    margin-left: auto;

    svg {
      margin-right: 0 !important;
      margin-top: 10px !important;
      margin-left: $g-unit !important;
    }
  }

  &--second-glyph {
    margin: 0 -4px 0 $g-unit !important;
  }

  &--link-like,
  &--link-like:hover,
  &--link-like:active {
    display: inline;
    border: none;
    color: $color-ui-primary;
    padding: 0;
    margin: 0;
    background: none;
    height: auto;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
    box-shadow: none;
    text-transform: none;
  }

  &__loader {
    margin: 0 $g-unit-half 0 (-1 * $g-unit-half);
  }

  svg {
    pointer-events: none;
  }

  &:active &__active-background {
    visibility: visible;
  }

  &__active-background {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $color-grey-2;
    border-radius: 50%;
    height: $g-unit-double;
    width: $g-unit-double;
    transform: translate3d(-50%, -50%, 0);
    visibility: hidden;
    pointer-events: none;
  }

  &#{&}--left-align {
    justify-content: flex-start;
  }

  &--left-align &__text {
    margin: 0;
  }

  &--spinner {
    display: flex;
    align-items: center;
    margin-right: $g-unit;
    margin-left: 2px;

    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }
}
