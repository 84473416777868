@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.RadioButton {
  display: flex;
  align-items: flex-start;
  min-height: 20px;

  //hides actual radio buttons
  &__input {
    position: absolute;
    visibility: hidden;
  }

  //colours the button when clicked on
  &__input:checked ~ .RadioButton__button {
    border: 6px solid $color-black-rebrand;
    box-shadow: none;
  }

  //colors the label when clicked on
  &__input:checked ~ .RadioButton__label {
    color: $color-black;
  }

  //styling for the button
  &__button {
    display: block;
    position: absolute;
    border: 1px solid $color-grey-2;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    z-index: 5;
    cursor: pointer;
    transition: border 0.2s linear;
    -webkit-transition: border 0.2s linear;
    background-color: white;
    box-shadow: 0px 1px 1px rgba(2, 14, 29, 0.08);
  }

  //styling and properties for the label
  &__label {
    font-size: $small-font-size;
    display: block;
    position: relative;
    padding-left: 28px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.2s linear;
    color: $color-black;
  }

  &__description {
    display: flex;
    align-items: flex-end;
    margin-left: $g-unit-half;
    font-size: $x-small-font-size;
    color: $color-grey-3-active;
    line-height: $small-font-size;
  }

  &--disabled {
    pointer-events: none;
    box-shadow: none;
    color: $color-white;
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
  }
}
