/*
 * ⚠️⚠️⚠️ STOP ⚠️⚠️⚠️
 * Before adding a new colour variable, please ensure that your colour doesn't already exist,
 * and that it has the same name as in our Figma designs.
 */

// Color primitives - when there is an applicable color token (see below), it should be used instead

$color-gold-100: #fff9eb;
$color-gold-200: #ffd781;
$color-gold-300: #e7bb56;
$color-gold-400: #ca9827;
$color-gold-500: #8b7237;
$color-teal-100: #e9f2f5;
$color-teal-200: #baf0f2;
$color-teal-300: #9deaec;
$color-teal-400: #64adc5;
$color-teal-500: #207d9c;
$color-plum-100: #f1ecfa;
$color-plum-200: #d0c1e8;
$color-plum-300: #9875d4;
$color-plum-400: #7b52c2;
$color-plum-500: #5b2fa6;

$color-slate-100: #f8f9fb;
$color-slate-200: #edeff2;
$color-slate-300: #e5e7eb;
$color-slate-400: #d1d5de;
$color-slate-500: #b2b6c1;
$color-slate-600: #898e9d;
$color-slate-700: #595f70;
$color-slate-800: #32394c;
$color-white: #ffffff;
// Ideally we'd just call this "color-black", but that's already taken and I don't want to rename everything as part
// of this PR. We should be using constants/css instead wherever possible anyways.
$color-black-rebrand: #171a1e;

$color-green-100: #e3faee;
$color-green-200: #c0f5d9;
$color-green-300: #77d9a5;
$color-green-400: #0dbc5e;
$color-green-500: #167e46;
$color-green-600: #035528;
$color-red-100: #fde4e8;
$color-red-200: #f9bdc9;
$color-red-300: #f16985;
$color-red-400: #e81641;
$color-red-500: #99122e;
$color-red-600: #5d0b1c;
$color-orange-100: #fff5e6;
$color-orange-200: #fee2b9;
$color-orange-300: #fcbd5e;
$color-orange-400: #fb9804;
$color-orange-500: #a16103;
$color-orange-600: #462b01;
$color-blue-100: #e5f2fd;
$color-blue-200: #abd1fb;
$color-blue-300: #539bf7;
$color-blue-400: #0c63d6;
$color-blue-500: #014db0;
$color-blue-600: #01316c;

// Color tokens - when one of these applies, it should be preferred over any color primitive
$color-text-headings: $color-black-rebrand;
$color-text-main: $color-black-rebrand;
$color-text-muted: $color-slate-700;
$color-text-inverted: $color-white;
$color-action-primary: $color-gold-300;
$color-action-primary-focus: $color-slate-300;
$color-action-primary-text: $color-teal-500;
$color-action-secondary: $color-teal-500;
$color-action-hover: $color-slate-200;
$color-action-disabled: $color-slate-400;
$color-status-positive: $color-green-400;
$color-status-warning: $color-orange-400;
$color-status-negative: $color-red-400;
$color-status-informative: $color-blue-400;
$color-background: $color-white;
$color-background-muted: $color-slate-100;
$color-background-disabled: $color-slate-200;
$color-border-primary: $color-gold-300;
$color-border-default: $color-slate-300;
$color-border-button: $color-slate-400;
$color-border-form-field: $color-slate-400;
$color-analytics-primary: $color-teal-500;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EVERYTHING BELOW HERE IS OUTDATED - WE SHOULD MIGRATE USAGES TO USE THE PRIMITIVES AND TOKENS ABOVE INSTEAD //
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Greyscale
$color-grey-bg: #f9f9f9;
$color-grey-1: #f3f3f5;
$color-grey-2: #e8e8eb;
$color-grey-2-active: #d9d9de;
$color-grey-3: #c4c4ca;
$color-grey-3-active: #adadb3;
$color-grey-4: #656568;
$color-grey-4-active: #535358;
$color-black: #101820;
$color-black-2: #000000;
$color-grey-mute: #3d444b;
$color-black-nav: #1c1431;

//Greyscale gradient fades. "left" means the color becomes more transparent to the left.
$fade-white-right: linear-gradient(
  to right,
  $color-white,
  rgba(255, 255, 255, 0)
);
$fade-white-left: linear-gradient(
  to left,
  $color-white,
  rgba(255, 255, 255, 0)
);
$fade-grey-bg-right: linear-gradient(
  to right,
  $color-grey-bg,
  rgba(249, 249, 249, 0)
);
$fade-grey-bg-left: linear-gradient(
  to left,
  $color-grey-bg,
  rgba(249, 249, 249, 0)
);

// Blue (UI Primary)
$color-ui-primary: #0975ef;
$color-ui-primary-active: #005dc7;
$color-ui-primary-100: #ebf3fe;
$color-ui-primary-150: #d4e6fc;
$color-ui-primary-200: #bedafb;

// Orange (UI Warn)
$color-ui-warn: #fb9804;
$color-ui-warn-active: #db7c00;

// Green (UI Good)
$color-ui-good: #39c379;
$color-ui-good-active: #23a45f;

// Red (UI Bad)
$color-ui-bad: #e81641;
$color-ui-bad-active: #c5072d;

// Yellow (UI Highlight)
$color-ui-highlight: #ffe560;

// Brown (Notice)
$color-notice-background: #fdf2cd;
$color-notice-foreground: #6b5027;
$color-notice-dismiss: #a48960;

// Purple (Meta)
$color-meta: #5d47aa;
$color-meta-light: #d7d0f0;

// UI Blocks
$color-ui-blocks-action: #2aa7d9;
$color-ui-blocks-message: #44c73b;
$color-ui-blocks-operation: $color-black;
$color-ui-blocks-note: #e3b640;

// UI Variables
$color-ui-variables-global: #c7f6dc;
$color-ui-variables-global-active: #004215;
$color-ui-variables-local: #c6e3ff;
$color-ui-variables-local-active: #002e66;
$color-ui-variables-web: #d7d0f0;
$color-ui-variables-web-active: #301e71;
$color-ui-variables-authentication: #fce1e7;
$color-ui-variables-authentication-active: #c5072d;
$color-ui-variables-sensitive: #f8e585;
$color-ui-variables-sensitive-active: #3f3815;

// Text Colors
$color-text-grey: #3c3c3f;
$color-text-light: $color-grey-3;

// JSON Viewer Colors:
$color-json-boolean: #ba2da3;
$color-json-string: #d82e1a;
$color-json-number: #2729d8;

// Analytics Colors
$color-analytics-star-gold: #f2b518;

$color-CSAT-0: #e81541;
$color-CSAT-1: #ec3c49;
$color-CSAT-2: #f26350;
$color-CSAT-3: #f58a57;
$color-CSAT-4: #fbb05f;
$color-CSAT-5: #ffd566;
$color-CSAT-6: #d7d26a;
$color-CSAT-7: #afce6e;
$color-CSAT-8: #89ca72;
$color-CSAT-9: #62c676;
$color-CSAT-10: #3bc379;

// Other Colours
$ada-color: #3a8afa;
$color-border: $color-grey-2;
$color-tag-default: #7379bf;
$color-mask: rgba(10, 15, 20, 0.4);
$color-input-flash: #fdffa2;
$color-dynamic-grey: rgba($color-grey-2, 0.75);

// Date Picker Blue Highlights
// (will be removed once new day picker component is implemented)
$color-blue-highlight: #90dded;
$color-light-blue-highlight: #daf8ff;

// does not work with colors containing alpha
// need this for including SVGs as background images with variable color fill
@function encodecolor($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}

$variable-colors: (
  global: (
    foreground: $color-ui-variables-global-active,
    background: $color-ui-variables-global,
    active-foreground: $color-white,
    active-background: $color-ui-variables-global-active,
  ),
  local: (
    foreground: $color-ui-variables-local-active,
    background: $color-ui-variables-local,
    active-foreground: $color-white,
    active-background: $color-ui-variables-local-active,
  ),
  oauth: (
    foreground: $color-ui-variables-authentication-active,
    background: $color-ui-variables-authentication,
    active-foreground: $color-white,
    active-background: $color-ui-variables-authentication-active,
  ),
  meta: (
    foreground: $color-meta,
    background: $color-ui-variables-web,
    active-foreground: $color-white,
    active-background: $color-ui-variables-web-active,
  ),
  sensitive: (
    foreground: $color-ui-variables-sensitive-active,
    background: $color-ui-variables-sensitive,
    active-foreground: $color-white,
    active-background: $color-ui-variables-sensitive-active,
  ),
  sensitive_meta: (
    foreground: $color-ui-variables-sensitive-active,
    background: $color-ui-variables-sensitive,
    active-foreground: $color-white,
    active-background: $color-ui-variables-sensitive-active,
  ),
  internal: (
    foreground: $color-ui-variables-local-active,
    background: $color-ui-variables-local,
    active-foreground: $color-white,
    active-background: $color-ui-variables-local-active,
  ),
  headers: (
    foreground: $color-meta,
    background: $color-meta-light,
    active-foreground: $color-white,
    active-background: $color-meta,
  ),
  client_secret: (
    foreground: $color-gold-500,
    background: $color-gold-200,
    active-foreground: $color-gold-500,
    active-background: $color-gold-200,
  ),
);

:export {
  colorWhite: $color-white;
  colorGrey1: $color-grey-1;
  colorGrey2: $color-grey-2;
  colorGrey3: $color-grey-3;
  colorGrey3Active: $color-grey-3-active;
  colorGrey4: $color-grey-4;
  colorGrey4Active: $color-grey-4-active;
  colorBlack: $color-black;
  colorUIPrimary: $color-ui-primary;
  colorUIGood: $color-ui-good;
  colorUIWarn: $color-ui-warn;
  colorUIWarnActive: $color-ui-warn-active;
  colorUIBad: $color-ui-bad;
  colorNoticeForeground: $color-notice-foreground;
  colorTextGrey: $color-text-grey;
  colorTagDefault: $color-tag-default;
  colorCSAT0: $color-CSAT-0;
  colorCSAT1: $color-CSAT-1;
  colorCSAT2: $color-CSAT-2;
  colorCSAT3: $color-CSAT-3;
  colorCSAT4: $color-CSAT-4;
  colorCSAT5: $color-CSAT-5;
  colorCSAT6: $color-CSAT-6;
  colorCSAT7: $color-CSAT-7;
  colorCSAT8: $color-CSAT-8;
  colorCSAT9: $color-CSAT-9;
  colorCSAT10: $color-CSAT-10;
}
