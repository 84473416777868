@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.ModalDelete {
  &__title {
    border-bottom: none !important;
  }

  &__description {
    width: 100%;
    height: 100%;
    text-align: center;
    color: $color-black;
    font-size: $main-font-size;
    padding-top: 0px !important;
    line-height: 125%;
  }

  &__bottom {
    padding: 0 !important;
  }

  &__button {
    width: 100%;
    height: 100%;
    border: none;
    font-size: $main-font-size;
    font-weight: $medium-font-weight;
    box-shadow: none !important;
    outline: none;
    padding: 8px 0;

    &__delete span {
      color: $color-ui-bad;
    }

    &__cancel {
      color: $color-black;
    }
  }
}
