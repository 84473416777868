@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/mixins.scss";
@import "~stylesheets/utilities/variables.scss";

.Modal {
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  pointer-events: none;

  @media print {
    display: none;
  }

  &--active {
    pointer-events: all;
  }

  &--active &__mask {
    background-color: $color-mask;
    transition: background-color 0.2s;
  }

  &--active:not(&--small):not(&--has-opacity-transition) &__container {
    top: 0;
    transition: top 0.2s;
  }

  &--small &__container {
    animation-name: smallModalDisappear;
    animation-duration: $transition-time;
  }

  &--active#{&}--small &__container {
    animation-name: smallModalAppear;
    animation-timing-function: cubic-bezier(0.25, 1.2, 0.67, 1.08);
    animation-duration: $transition-time;
    opacity: 1;
  }

  /* Fade in & out animation when isOpacityTransition is true */
  &--has-opacity-transition &__container {
    transition: opacity 0.5s ease;
  }

  &--active#{&}--has-opacity-transition &__container {
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  /* End of Fade in & out animation */

  &__mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-modal-mask;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
    outline: none;
  }

  &__container {
    z-index: $z-index-modal-container !important;
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-self: center;

    &:not(&--small, &--has-opacity-transition) {
      top: 100%;
      transition: top $transition-time;
    }

    &--small {
      top: 0%;
      max-width: 360px !important;
      transition: opacity $transition-time;
      opacity: 0;
    }

    &--has-opacity-transition {
      top: 0;
      transition: opacity 0.5s ease;
      opacity: 0;
    }

    &--large {
      max-width: 800px;
    }

    &__close {
      position: absolute;
      top: $g-unit;
      right: $g-unit;
      z-index: 3;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &--video {
        top: -48px;
        right: 0px;
        background-color: $color-plum-400;

        &:active {
          background-color: $color-plum-100;
        }
      }
    }
  }

  &__inner-container {
    background-color: $color-white;
    border-radius: $main-border-radius;
    box-shadow: $box-shadow;
    transform: translate3d(0, 0, 0);

    &__overflow-visible {
      overflow: visible;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    height: $modal-max-height;

    &--small {
      height: auto;
    }

    &--fixed {
      height: 560px;
    }
  }

  &__scroll {
    overflow-y: scroll;
    height: 700px;
  }

  &__title {
    @include truncate-line;

    padding: $g-unit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-3-active;
    border-bottom: 1px solid $color-border;
    box-sizing: content-box;
    height: $input-height;
    margin: 0;
    flex-shrink: 0;

    &--small {
      border-bottom: 0 transparent;
    }
  }

  &__body {
    display: flex;
    flex: 1;
    height: 0; // Fixes the flex bug in Chrome 72

    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
    }
  }

  &__content {
    flex: 1;
    padding: $g-unit-double;
    overflow-y: auto;
    max-height: $modal-max-height;

    &--small {
      height: 50px;
    }

    &--no-top-padding {
      padding-top: 0;
    }
  }

  &__section {
    margin-bottom: $g-unit-double;
    padding-bottom: $g-unit-double;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &__sub-section {
      flex: 1;

      &:not(:last-child) {
        margin-right: $g-unit;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__title {
        font-size: $small-font-size;
        font-weight: $medium-font-weight;
      }

      &__description {
        margin: 0 0 $g-unit 0;
        font-size: $small-font-size;
        color: $color-grey-4;
        white-space: pre-wrap;

        &--below {
          margin: $g-unit 0 0 0;
        }

        a {
          font-size: inherit;
        }
      }
    }

    &__description {
      margin: -$g-unit-half 0 $g-unit 0;
      font-size: $small-font-size;
      color: $color-grey-4;
      white-space: pre-wrap;

      a {
        font-size: inherit;
      }
    }

    &__title {
      margin-bottom: $g-unit;
      color: $color-black;
      font-size: $main-font-size;
      font-weight: $medium-font-weight;
      line-height: $large-line-height;
    }
  }

  &__bottom {
    width: 100%;
    border-radius: 0 0 $main-border-radius $main-border-radius;
    border-top: 1px solid $color-border;
    padding: $g-unit-double;
    display: flex;
    background-color: $color-white;
    justify-content: flex-end;
    flex-shrink: 0;

    &--right {
      justify-content: flex-end;
    }

    &__spaced {
      justify-content: space-between;
    }

    &__warning {
      font-weight: $medium-font-weight;
      display: flex;
      align-self: center;
      align-items: center;
      font-size: $xx-small-font-size;
      color: $color-ui-warn;
      margin: 0 $g-unit;

      &__icon {
        width: 20px;
        height: 20px;
        margin-top: 2px;
        margin-right: $g-unit-half;
      }
    }
  }

  &__bottom__padding_between {
    width: 100%;
    border-radius: 0 0 $main-border-radius $main-border-radius;
    border-top: 1px solid $color-border;
    padding: $g-unit $g-unit;
    display: flex;
    gap: $g-unit;
    background-color: $color-white;
    justify-content: flex-start;
  }

  &__bottom__vertical {
    width: 100%;
    border-radius: 0 0 $main-border-radius $main-border-radius;
    border-top: 1px solid $color-border;
    padding: $g-unit $g-unit;
    display: flex;
    gap: $g-unit;
    background-color: $color-white;
    justify-content: flex-start;
    flex-direction: column;

    &__spaced {
      width: 100%;
      display: flex;
      gap: $g-unit;
      background-color: $color-white;
      justify-content: flex-end;
    }
  }
}

/* Prevent background scrolling when a modal is open */
body.Modal__body-open {
  overflow: hidden;
}

@keyframes smallModalAppear {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes smallModalDisappear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
