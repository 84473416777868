@import "~stylesheets/utilities/variables.scss";
@import "~stylesheets/utilities/colors.scss";

.InputSearch {
  position: relative;
  font-size: $main-font-size;
  &__search-icon-container {
    height: 100%;
    position: absolute;
    top: 0;
    left: $g-unit;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity $input-animation-time ease;
    pointer-events: none;

    &__icon {
      height: 26px;
      width: 26px;
    }
  }

  &__input {
    padding-right: 4 * $g-unit !important;
    &--invalid {
      border: 1px solid $color-ui-warn !important;
    }

    &:focus {
      padding-left: 5 * $g-unit;
    }

    &--right-icon {
      padding-right: 4 * $g-unit !important;
    }
  }

  &__input:focus + &__search-icon-container {
    opacity: 1;
  }

  &__gradient {
    height: 26px;
    width: 36px;
    position: absolute;
    top: 1px;
    right: 4 * $g-unit;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
    transition: z-index $input-animation-time steps(180, end);

    &--invalid {
      border-top: 2px solid $color-ui-warn;
      border-bottom: 2px solid $color-ui-warn;
    }
  }

  &__input:focus ~ &__gradient {
    z-index: -90;
    transition: none !important;
  }

  &__icon-container {
    height: 100%;
    position: absolute;
    top: 0;
    right: $g-unit;
    display: flex;
    align-items: center;
    transition: opacity $input-animation-time ease;
    cursor: pointer;
    color: $color-grey-4;
    transition: all $transition-time ease;
    padding-right: -5 * $g-unit;

    &:active {
      color: $color-black;
    }

    &--type-chevron {
      pointer-events: none;
    }

    &__icon {
      height: 20px;
      width: 20px;
    }
  }
}
